import { SeletcedProducts } from 'base/types/Products';
import {
  recommendationResults,
  RankedSeed,
} from 'base/types/RecommendationCropwise';
import { RecommendationPackageAPIResponse } from 'base/types/RecommendationPackageAPI';

export const getRecommendedSeeds = ({ SelectedSeed, SeedRankings }: recommendationResults): (RankedSeed | { SeedName: string; })[] => {
  const rankedSeedsWithoutRecommendedSeed = SeedRankings.filter(
    ({ Seed }) => Seed?.SeedName !== SelectedSeed.SeedName
  );
  return [SelectedSeed, ...rankedSeedsWithoutRecommendedSeed];
};

export const checkIfNoSeedsVarietiesAvailable = (result: RecommendationPackageAPIResponse): boolean => {
  return result.SelectedSeeds.every(({ Brand, Name }: SeletcedProducts) => Brand === 'None' && Name === 'None');
};
