/* eslint-disable indent */
/* eslint-disable array-bracket-newline */
import { useEffect, useState } from 'react';
import RadarChart from 'components/RadarChart';
import {
  StyledProduct,
  ProductDotColor,
  StyledCaptionsContainer,
  StyledCol,
  StyledAcronym,
  StyledCaption,
} from './ProductComparison.styles';
import { useTranslation } from 'react-i18next';
import { Row } from 'syngenta-digital-cropwise-react-ui-kit';
import { ProductSeed } from 'base/types/Products';
import { useAppState } from 'context/AppState';
import { useBreakpoint } from 'hooks';
import { isRomania } from 'utils/constants/CountryData';
import {
  ChartContainer,
  ProductComparisonContent,
  ProductComparisonTitle,
  ProductList,
  SectionContainer,
  Text,
} from 'pages/RecommendationV2/V2Common.styles';

interface IAttribute {
  [key: string]: string;
}

export default function ProductComparison({ sectionRef }: { readonly sectionRef?: any }) {
  const [producList, setProducList] = useState<ProductSeed[]>([]);
  const {
    apiData: { productCatalog, attributes, recommendationCropwise },
  } = useAppState();
  const screens = useBreakpoint();
  const { isMobile, isTablet, portrait, landscape } = screens;
  const [chartData, setChartData] = useState([]);
  const [captions, setCaptions] = useState<IAttribute | null>();
  const [mobileCaptions, setMobileCaptions] = useState<IAttribute | null>();
  const { t } = useTranslation();

  const options = {
    zoomDistance: 1.78,
    dots: true,
    axes: true,
    axesType: 'line',
    scales: isRomania(recommendationCropwise?.country?.toLocaleUpperCase()) ? 9 : 10,
    captions: true,
    captionLineHeight: 49,
    captionProps: (col: { angle: number }) => {
      const degrees = (col.angle * 180) / Math.PI;
      let textAnchor = degrees === 0 || degrees === 180 ? 'middle' : 'start';
      if (degrees > 180) {
        textAnchor = 'end';
      }

      return {
        fontSize: isTablet ? 13 : 16,
        className: 'caption',
        margin: isMobile ? 26 : 38,
        textAnchor,
      };
    },
    dotProps: (meta: any) => {
      return {
        r: 6,
        fill: meta.color,
      };
    },
  };

  useEffect(() => {
    const products = recommendationCropwise?.recommendations[0]?.products ?? [];

    const fetchProducts = async () => {
      if (productCatalog.length) {
        const selected = products
          .filter((seed) => seed.product_name !== 'None')
          .map((seed) => {
            const foundProduct = productCatalog.find(
              (item) => seed.product_name === item.productName
            );
            return foundProduct
              ? {
                  ...foundProduct,
                  color: seed.color,
                }
              : null;
          })
          .filter((item) => item);
        setProducList(selected as ProductSeed[]);
      }
    };

    fetchProducts();
  }, [productCatalog, recommendationCropwise]);

  useEffect(() => {
    // set chart data
    let filteredAttributes = attributes.filter(
      (item) => item.attributeId !== 'positioning' && item?.usageGroups.includes('pdf')
    ); // skip positioning attribute
    filteredAttributes = filteredAttributes.sort(
      (firstItem, secondItem) => firstItem.id - secondItem.id
    );

    const attributesObject: IAttribute = {};
    filteredAttributes.forEach((item) => {
      attributesObject[`${item.attributeId}`] = t(`${item.attributeName}`);
    });
    setCaptions(attributesObject);

    if (isMobile) {
      const shortCaptions = { ...attributesObject };
      const usedValues: string[] = [];

      Object.keys(shortCaptions).forEach((item) => {
        const longName = shortCaptions[item];
        let shortName = longName
          .split(/\s/)
          .map((word) => word.slice(0, 1))
          .join('');

        shortName = shortName.slice(0, 3);
        if (usedValues.includes(shortName)) {
          const count = usedValues.filter((obj) => obj === shortName).length;
          usedValues.push(shortName);
          shortName = `${shortName}${count}`;
        } else {
          usedValues.push(shortName);
        }
        shortCaptions[item] = shortName;
      });

      setMobileCaptions(shortCaptions);
    }

    const attributeColumns = Object.keys(attributesObject);
    type AttributeKeys = keyof typeof captions;

    const newData = producList.map((product, index) => {
      return {
        data: {
          ...attributeColumns.reduce((obj, attributeId) => {
            /**
             * Formula
             * Document: https://digitial-product-engineering.atlassian.net/wiki/spaces/CS/pages/2338488890/Product+Compare
             * (0.5 + (productAttrbuteValue - attributeWorstvalue ) / (attributeBestvalue  - attributeWorstvalue ) / 2)
             */

            const attr = attributes.find((item) => {
              return item.attributeId === attributeId;
            }) || { attributeWorstvalue: 1, attributeBestvalue: 9 };

            const value =
              0.5 +
              (product[attributeId as AttributeKeys] - attr.attributeWorstvalue) /
                (attr.attributeBestvalue - attr.attributeWorstvalue) /
                2;

            return {
              ...obj,
              [attributeId]: value > 1 ? 1 : value,
            };
          }, {}),
        },
        meta: {
          color: product.color,
          strokeWidth: 4,
        },
      };
    });

    if (newData.length > 0) {
      setChartData(newData as any);
    }
  }, [producList, attributes, t, isMobile]);
  const calculateSize = () => {
    let size = 950;
    if (isMobile) {
      size = window.screen.width;
    } else if (isTablet) {
      if (portrait) {
        size = window.screen.width - 110;
      } else if (landscape) {
        size = 615;
      }
    }
    return size;
  };
  return (
    <SectionContainer data-testid="product-comparison-section" ref={sectionRef}>
      <ProductComparisonContent>
        <ProductComparisonTitle>{t('Product Comparison')}</ProductComparisonTitle>
        <Text>{t('productComparisonDescription')}</Text>

        <ChartContainer>
          {(captions || mobileCaptions) && (
            <RadarChart
              captions={isMobile ? mobileCaptions : captions}
              data={chartData}
              size={calculateSize()}
              options={options}
            />
          )}
        </ChartContainer>
        <ProductList>
          {producList.map((produc, index) => {
            return (
              <StyledProduct key={produc.productName}>
                <ProductDotColor color={produc.color ?? ''} />
                {produc.commercialName}
              </StyledProduct>
            );
          })}
        </ProductList>
        {isMobile && captions && mobileCaptions && (
          <StyledCaptionsContainer>
            <Row>
              {Object.keys(captions).map((key: keyof IAttribute) => (
                <StyledCol key={key} span={12}>
                  <StyledAcronym>{mobileCaptions[key]}</StyledAcronym>
                  <StyledCaption>{captions[key]}</StyledCaption>
                </StyledCol>
              ))}
            </Row>
          </StyledCaptionsContainer>
        )}
      </ProductComparisonContent>
    </SectionContainer>
  );
}
