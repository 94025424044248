
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import track from 'utils/amplitudeWrapper';
import {
  TableStyled,
  TrStyled,
  RankText,
  VarietyText,
  TrExpandableRow,
  TableContainer,
  ExpandedTdStyled,
  NumberText,
  CommonText,
  DescriptionText,
  SkuContainer,
} from './DensityDemandTableMobile.styles';
import { useTranslation } from 'react-i18next';
import { IconChevronRightStyled, TdMainRow } from '../DensityDemandTable/styles';
import { SeedDensityField } from '../SeedDensityAdvice';
import { RankedSeed } from 'base/types/RecommendationCropwise';
import { numberToEuropeanFormat } from 'utils/numberFormat';
import FieldCeil from '../FieldCeilComponent';
import { useAppState } from 'context/AppState';

type TSeedDemand = {
  rank: number | string;
  variety: string;
  seeds: string | number;
  plantingArea: string | number;
  numberOfBags: string | number;
};

type SkuDataItem = {
  commercial_name: string;
  sku_description: string;
};

interface IProps {
  dataSource: SeedDensityField[];
  isNonDDG: boolean;
}

export type TData = {
  fieldName: string;
  fieldCoordinates: number[][][];
  seedDemand: TSeedDemand[];
  area: string | number;
  expanded?: boolean;
};

const MainRow = ({
  data,
  handleExpandableRowClick,
}: {
  data: SeedDensityField;
  handleExpandableRowClick: (rowData: SeedDensityField) => void;
}) => {
  return (
    <TrStyled>
      <td>
        <FieldCeil {...data} />
      </td>
      <TdMainRow hasPaddingRight={true}>
        <IconChevronRightStyled
          isOpened={data.expanded}
          onClick={() => handleExpandableRowClick(data)}
        />
      </TdMainRow>
    </TrStyled>
  );
};

const ExpandableRow = ({ data, isNonDDG, skuData }: { data: RankedSeed; isNonDDG: boolean, skuData: SkuDataItem[] }) => {
  const [t] = useTranslation();
  const skuDetails = skuData.find(
    (item: { commercial_name: string; }) =>
      item.commercial_name === data.variety
  );
  const {
    apiData: { productCatalog },
  } = useAppState();

  return (
    <TrExpandableRow>
      <ExpandedTdStyled>
        <RankText>{data.rank}</RankText>
        <VarietyText>{data.variety}</VarietyText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Seeds/ha')}</CommonText>
        <NumberText>{numberToEuropeanFormat(data.seeds as number)}</NumberText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Area (ha)')}</CommonText>
        <NumberText>{numberToEuropeanFormat(data.plantingArea as number)}</NumberText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Bags')}</CommonText>
        <NumberText>{numberToEuropeanFormat(data.numberOfBags as number)}</NumberText>
      </ExpandedTdStyled>
      {isNonDDG && (
        <SkuContainer>
          <ExpandedTdStyled>
            <CommonText>{t('SKU')}</CommonText>
          </ExpandedTdStyled>
          <ExpandedTdStyled>
            <DescriptionText className='description'>{skuDetails?.sku_description ?? 'N/A'}</DescriptionText>
          </ExpandedTdStyled>
        </SkuContainer>
      )}
    </TrExpandableRow>
  );
};

const DensityDemandTableMobile = ({ dataSource, isNonDDG }: IProps) => {
  const [dataToDisplay, setDataToDisplay] = useState<SeedDensityField[]>([]);
  const [skuData, setSkuData] = useState<SkuDataItem[]>([]);
  const {
    apiData: { recommendationCropwise, productCatalog },
  } = useAppState();

  const getSKUForNonDDGSolutions = (productName: string) => {
    const recommendatedProduct = productCatalog?.find((item) => item.productName === productName);
    return recommendatedProduct?.sku[0]?.description;
  };

  useEffect(() => {
    const updatedDataSource = dataSource.map((field, index) => ({
      ...field,
      expanded: index === 0,
    }));
    setDataToDisplay(updatedDataSource);
  }, [dataSource]);

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const skuDataFetch = recommendationCropwise.recommendations[0].products.map((product) => ({
        commercial_name: product.commercial_name,
        sku_description: product.sku?.description || getSKUForNonDDGSolutions(product.product_name) || '',
      }));
      setSkuData(skuDataFetch);
    }
  }, [recommendationCropwise]);

  const handleExpandableRowClick = (rowData: SeedDensityField) => {
    const dataToDisplayCopy = [...dataToDisplay].map((row) => {
      if (row.name === rowData.name) {
        return {
          ...row,
          expanded: !row.expanded,
        };
      }
      return row;
    });
    setDataToDisplay(dataToDisplayCopy);
    track('recommendation interaction', { 'seed advice interaction': true });
  };

  const renderExpandableRow = (field: SeedDensityField) => {
    const seedsDemand = [...field.seedDemand].filter((seedDemand) => {
      const rank =
        typeof seedDemand.rank === 'string' ? parseInt(seedDemand.rank) : seedDemand.rank;
      return rank < 4;
    }); // We only need the first 3 ranks
    return (
      <>
        <MainRow data={field} handleExpandableRowClick={handleExpandableRowClick} />
        {field.expanded && seedsDemand.map((seed) => <ExpandableRow key={seed.rank} data={seed} isNonDDG={isNonDDG} skuData={skuData} />)}
      </>
    );
  };

  return (
    <TableContainer>
      <TableStyled>
        <tbody>
          {dataToDisplay.map((densityDemandInfo) => renderExpandableRow(densityDemandInfo))}
        </tbody>
      </TableStyled>
    </TableContainer>
  );
};

export default DensityDemandTableMobile;
