export enum MapStyles {
  'SATELLITE' = 'mapbox://styles/mapbox/satellite-v9',
  'HYBRID' = 'mapbox://styles/mapbox/satellite-streets-v11',
  'STREETS' = 'mapbox://styles/mapbox/streets-v12',
}

export const MapsConstants = {
  LATITUDE_DEFAULT: 48.002242,
  LONGITUDE_DEFAULT: 22.902138,
  ZOOM_DEFAULT: 5,
  MAP_STYLE_URL: MapStyles.HYBRID,
  IPAPI_URL: 'https://ipapi.co/json/',
  FIELDS_LAYER_ID: 'fields-layer',

  GEOCODER_LIMIT_SUGGESTIONS: 4,
  GEOCODER_MIN_LENGHT: 3,
  GEOCODER_COUNTRIES_INCLUDING_BRAZIL: 'BR,HU,PL,RO,DE,UA,BG,IT,ZA',
  GEOCODER_COUNTRIES: 'HU,PL,RO,DE,UA,BG,IT,ZA',

  ZOOM_IN_LIMIT_ROMANIA: 6,
  ZOOM_IN_LIMIT_HUNGARY: 6,
  ZOOM_IN_LIMIT_POLAND: 5,
  ZOOM_IN_LIMIT_BRAZIL: 4,
  ZOOM_IN_LIMIT_GERMANY: 5,
  ZOOM_IN_LIMIT_ITALY: 5,
  ZOOM_IN_LIMIT_BULGARIA: 6,
  ZOOM_IN_LIMIT_UKRAINE: 5,
  ZOOM_IN_LIMIT_SOUTH_AFRICA: 4,
  ZOOM_IN_LIMIT_TRIALING_INFO_MAP: 9.85,
  MIN_ZOOM_LEVEL_FOR_DRAW_OR_DROP_A_PIN: 13,
};
