import { StyledContainer } from './ButtonGroup.styles';
import ButtonBase, { ButtonBaseProps } from 'components/Buttons/ButtonBase';
import useValidationSteps from 'pages/BoundaryWizard/hooks/useValidationSteps';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'context/AppState';

import { CSSProperties } from 'react';

interface IProps {
  id?: string;
  buttonLeft: ButtonBaseProps;
  buttonRight: ButtonBaseProps;
  style?: CSSProperties;
  className?: string;
}

const ButtonGroup = ({
  id,
  buttonLeft,
  buttonRight,
  style,
  className,
}: IProps): JSX.Element => {
  const [t] = useTranslation();
  const {
    flow: { currentStep, currentModeType, isContinueButtonActive, isAnotherRecommendationCreated },
    recommendationForm: {
      fields,
      agronomicInputs: {
        plantingDate,
        yieldRangeId,
        agProducts,
        rotationIntensity,
        tillagePractice,
        selectedHerbicideTrait,
        selectedSegment,
        isBroomrapeInfestation,
        selectedBroomrape,
      },
      selectedCrop,
    },
  } = useAppState();
  const { disabledNextBtn, disabledPrevBtn, nextButtonText, backButtonText, isCancelButton } = useValidationSteps({
    currentStep,
    currentModeType,
    agProducts,
    plantingDate,
    selectedCrop,
    yieldRangeId,
    rotationIntensity,
    tillagePractice,
    selectedHerbicideTrait,
    selectedSegment,
    isBroomrapeInfestation,
    selectedBroomrape,
    fields,
    isContinueButtonActive,
    isAnotherRecommendationCreated,
  });

  return (
    <StyledContainer id={id} data-testid={id} style={style} className={className}>
      <ButtonBase
        textSize="MEDIUM"
        text={t(backButtonText)}
        isDisabled={disabledPrevBtn}
        {...buttonLeft}
        isCancelButton={isCancelButton}
        data-testid="cancel-button"
      />
      <ButtonBase
        textSize="MEDIUM"
        text={buttonRight?.text ? buttonRight?.text : t(nextButtonText)}
        buttonProps={{ type: 'primary' }}
        isDisabled={disabledNextBtn}
        {...buttonRight}
        data-testid="next-button"
      />
    </StyledContainer>
  );
};

export default ButtonGroup;
