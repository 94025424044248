import React from 'react';
import FieldPreview from 'components/FieldPreview';
import { SeedDensityField } from '../SeedDensityAdvice';
import {
  FieldCeilContainer,
  FieldCeilPreviewContainer,
  FieldCeilTextContainer,
  FieldNameText,
  FieldAreaText,
} from './DensityDemandTable/styles';
import { numberToEuropeanFormat } from 'utils/numberFormat';

const FieldCeil = ({ geometry, name, estimatedSize, FieldId }: SeedDensityField) => (
  <FieldCeilContainer>
    <FieldCeilPreviewContainer>
      <FieldPreview
        id={`svg-field-${FieldId}`}
        size={50}
        coords={geometry.coordinates as unknown as [number, number][]}
        viewboxSize={50}
      />
    </FieldCeilPreviewContainer>
    <FieldCeilTextContainer>
      <div>
        <FieldNameText>{name}</FieldNameText>
        <br />
        <FieldAreaText>{`${numberToEuropeanFormat(estimatedSize)} ha`}</FieldAreaText>
      </div>
    </FieldCeilTextContainer>
  </FieldCeilContainer>
);

export default FieldCeil;
