export const SegmentOptions = [
  {
    label: 'Linoleic',
    value: 'Linoleic'
  },
  {
    label: 'High oleic',
    value: 'High oleic'
  }
];
