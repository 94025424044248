import {
  SoilInfoSection,
  SoilDetailSection,
  FieldSoilSection,
  Text,
  DividerText,
  HectareText,
  SoilColor,
} from './PropertySoilInfo.styles';
import { useTranslation } from 'react-i18next';
import { SoilPopData } from '../SoilCharacteristics';

interface Field {
  data?: SoilPopData[];
}
const PropertySoilInfo = ({ data }: Field): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {data?.map((item) => (
        <SoilInfoSection key={item.name}>
          <FieldSoilSection>
            <SoilColor color={item.color} />
          </FieldSoilSection>
          <SoilDetailSection>
            <Text>{item.name}</Text>
            <DividerText>|</DividerText>
            <HectareText>{`${Number(item.size).toFixed(2)} ${t('ha')}`}</HectareText>
          </SoilDetailSection>
        </SoilInfoSection>
      ))}
    </>
  );
};

export default PropertySoilInfo;
