window.sessionStorage.setItem('devicePixelRatio', String(window.devicePixelRatio));

export default function getZoomLevel(): number | boolean {
  const isFirefox = navigator.userAgent.search('Firefox') > -1;
  const devicePixelRatio = Number(window.sessionStorage.getItem('devicePixelRatio') ?? '1');
  const zoom = Math.round((window.outerWidth / window.innerWidth) * 100);

  if (isFirefox) {
    const query = `(max--moz-device-pixel-ratio:${
      devicePixelRatio - 0.01
    }), (min--moz-device-pixel-ratio:${devicePixelRatio + 0.01})`;
    const isZoomed = window.matchMedia(query).matches;

    return isZoomed;
  } else {
    return zoom;
  }
}
