import { useState } from 'react';
import {
  StyledContainer,
  StyledButton,
  StyledModalView,
  Title,
  SheetText,
  SheetValueText,
} from './AgronomicPreferencesSheet.style';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { ModalAnimationDirections } from 'base/types/ModalBox';

interface BottomSheetProps {
  title: string;
  onClickViewDetails: Function;
  onClickOutside: Function;
  yieldRange?: string;
  plantingPeriod?: string;
  tillagePractice?: string;
}

const BottomSheet = ({
  title,
  onClickViewDetails,
  onClickOutside,
  yieldRange,
  plantingPeriod,
  tillagePractice,
}: BottomSheetProps): JSX.Element => {
  const [t] = useTranslation();
  const [slideDirection, setSlideDirection] = useState(ModalAnimationDirections.Up);

  const handleButtonClick = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    onClickViewDetails();
  };

  const handleCloseDrawer = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    if (onClickOutside) {
      onClickOutside();
    }
  };

  return (
    <div data-testid="Agro-preferences-sheet">
      <StyledModalView
        data-testid="Agro-preferences-sheet"
        width={494}
        isViewFooter
        showHeaderRectangle
        onCancel={handleCloseDrawer}
        content={
          <>
            <Title>{title}</Title>
            <StyledContainer>
              <SheetText>
                {t('Historic yield range ')}
                <SheetValueText> : {yieldRange}</SheetValueText>
              </SheetText>
              {plantingPeriod && (
                <SheetText>
                  {t('Planting period ')}
                  <SheetValueText> : {plantingPeriod}</SheetValueText>
                </SheetText>
              )}
              {tillagePractice && (
                <SheetText>
                  {t('Tillage Practice ')}
                  <SheetValueText> : {tillagePractice}</SheetValueText>
                </SheetText>
              )}
            </StyledContainer>
          </>
        }
        footer={
          <StyledButton type={ButtonType.default} size={'large'} onClick={handleButtonClick}>
            {t('View Details')}
          </StyledButton>
        }
        slideDirection={slideDirection}
      />
    </div>
  );
};

export default BottomSheet;
