import { useTranslation } from 'react-i18next';
import { CropConstants } from 'utils/constants/Crop';
import { isHungary } from 'utils/countryCode';
import category from 'assets/icons/category.svg';
import Union from 'assets/icons/Union.svg';
import grain from 'assets/icons/grain.svg';

interface SquareSvgProps {
  readonly color: string;
}

function SquareSvg({ color }: SquareSvgProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="field">
        <path
          id="icon/maps/area_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3H7V5L17 5V3H19V5L21 5V7H19V17H21V19H19V21H17V19L7 19V21H5V19H3V17H5V7H3V5L5 5V3ZM7 17V7L17 7V17L7 17Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
import {
  ProductNameText,
  Title as CardTitle,
  TitleInformationText,
  ValueInformationText,
  ValueText,
  CardContainer,
  CardTitleContainer,
  CardContentContainer,
  ProductNameContainer,
  ProductInformationContainer,
  SeedRateContainer,
  BreakoutsInformationContainer,
  BreakoutsOptionContainer,
  DividerStyled,
  DividerContainer,
  FaoContainer,
  FaoText,
  GrainTypeContainer,
  CategoryContainer,
  StyledTitleContainer,
  MainContainer,
  ValueInformationIcon,
  ValueInformationDescription,
  ViewApplicableField,
  ViewApplicableText,
  ViewApplicableIcon,
  HybridProfileText,
  CropProductNameContainer,
  HybridCollapse,
  HybridPanel,
} from './SeedCard.styles';

export interface IProps {
  productName: string;
  cardTitle?: string;
  commercialName?: string;
  seedRate?: string | number;
  grainType?: string;
  description?: string[] | string;
  locationSummary?: string | number;
  locationsYieldData?: number | string;
  avgYieldSummary?: string | number;
  plusMinCheck?: string | number;
  isSelected?: boolean;
  fao?: string | number;
  handleOnClick?: (productName: string) => void;
  isMobile?: boolean;
  cropName?: string;
  marketSegment?: string;
  herbicideTechnology?: string;
  broomrapeTechnology?: string;
  technology?: string;
  countryName?: string;
  index?: number;
  color?: string;
  isDDG?: boolean;
  recommendedFieldCount?: number;
  size?: number;
}

function europeFormatNumber(number: number | string) {
  const parsedNum = typeof number === 'string' ? parseFloat(number.replace(',', '.')) : number;
  return parsedNum;
}

const SunflowerProductContainer = ({
  marketSegment,
  herbicideTechnology,
  broomrapeTechnology,
}: {
  marketSegment: string;
  herbicideTechnology: string;
  broomrapeTechnology: string;
}) => {
  const { t } = useTranslation();
  const data = [
    { name: 'Oil Trait', value: marketSegment },
    { name: 'Herbicide Tech', value: herbicideTechnology },
    { name: 'Broomrape Trait', value: broomrapeTechnology },
  ];
  return (
    <MainContainer>
      {data.map((item) => (
        <StyledTitleContainer key={item.name}>
          <TitleInformationText>
            {t(item.name)}: <ValueInformationText>{t(item.value)}</ValueInformationText>
          </TitleInformationText>
        </StyledTitleContainer>
      ))}
    </MainContainer>
  );
};

const SeedCard = ({
  productName,
  cardTitle,
  commercialName,
  seedRate,
  grainType,
  description,
  locationSummary,
  locationsYieldData,
  avgYieldSummary,
  plusMinCheck,
  isSelected,
  fao,
  marketSegment = '',
  herbicideTechnology = '',
  broomrapeTechnology = '',
  isMobile = false,
  cropName,
  technology,
  handleOnClick,
  countryName,
  index,
  color,
  isDDG,
  recommendedFieldCount,
  size,
}: IProps) => {
  const { t } = useTranslation();

  const getSeedRateUnit = () => {
    switch (true) {
      case countryName === CropConstants.Bulgaria:
        return t('seeds/decare');
      case countryName === CropConstants.SOUTH_AFRICA:
        return t('kg/ha');
      default:
        return t('seeds/ha');
    }
  };
  const renderDescription = () => {
    if (countryName === CropConstants.SOUTH_AFRICA && Array.isArray(description)) {
      return (
        <ul>
          {description?.map((item: string, index: number) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      );
    }
    return description;
  };
  const renderProductInformation = () => (
    <ProductInformationContainer>
      <div>
        <ValueInformationDescription>{renderDescription()}</ValueInformationDescription>
      </div>
      {technology && (
        <CategoryContainer>
          <TitleInformationText>
            <ValueInformationIcon>
              <img src={category} alt="category" />
            </ValueInformationIcon>
            {`${t('Category')}: `}
            <ValueInformationText>{t(` ${technology}`)}</ValueInformationText>
          </TitleInformationText>
        </CategoryContainer>
      )}
      <SeedRateContainer>
        <TitleInformationText>
          <ValueInformationIcon>
            <img src={Union} alt="Union" />
          </ValueInformationIcon>
          {t('Seed Rate')}:{' '}
          <ValueInformationText>
            {seedRate && europeFormatNumber(seedRate).toFixed(2)} {getSeedRateUnit()}
          </ValueInformationText>
        </TitleInformationText>
      </SeedRateContainer>
      {grainType && (
        <GrainTypeContainer>
          <TitleInformationText>
            <ValueInformationIcon>
              <img src={grain} alt="grain" />
            </ValueInformationIcon>
            {t('Grain Type')}: <ValueInformationText>{grainType}</ValueInformationText>
          </TitleInformationText>
        </GrainTypeContainer>
      )}
    </ProductInformationContainer>
  );

  const renderBreakoutsInformation = () => (
    <BreakoutsInformationContainer>
      <BreakoutsOptionContainer>
        <ValueInformationText>{t('Total Trials')}</ValueInformationText>
        <ValueText>{locationSummary}</ValueText>
      </BreakoutsOptionContainer>
      <BreakoutsOptionContainer applyMargin>
        <ValueInformationText>{t('Matching Environments')}</ValueInformationText>
        <ValueText>{locationsYieldData}</ValueText>
      </BreakoutsOptionContainer>
      <BreakoutsOptionContainer applyMargin>
        <ValueInformationText>
          {cropName === CropConstants.SUNFLOWER_SMALLCASE && isHungary(countryName)
            ? t('Average Yield Hungary')
            : t('Average Yield')}
        </ValueInformationText>
        <ValueText>{avgYieldSummary && europeFormatNumber(avgYieldSummary)}</ValueText>
      </BreakoutsOptionContainer>

      <BreakoutsOptionContainer>
        <ValueInformationText>+/- {t('Check')}%*</ValueInformationText>
        <ValueText>{plusMinCheck && europeFormatNumber(plusMinCheck)}%</ValueText>
      </BreakoutsOptionContainer>
      {!isDDG && !isMobile && (
        <ViewApplicableField color={color} onClick={() => handleOnClick?.(productName)}>
          <ViewApplicableIcon>{color && <SquareSvg color={color} />}</ViewApplicableIcon>
          <ViewApplicableText color={color}>
            {t(`View ${recommendedFieldCount} applicable Fields`)}
          </ViewApplicableText>{' '}
        </ViewApplicableField>
      )}
    </BreakoutsInformationContainer>
  );

  return (
    <CardContainer isMobile={isMobile} selected={isSelected} isDDG={isDDG} size={size}>
      {index === 0 && (
        <CardTitleContainer selected={isSelected}>
          <CardTitle>{cardTitle}</CardTitle>
        </CardTitleContainer>
      )}
      <CardContentContainer>
        {cropName === CropConstants.SUNFLOWER_SMALLCASE ? (
          <ProductNameContainer isDDG={isDDG} selected={isSelected} isMobile={isMobile}>
            <ProductNameText>{commercialName}</ProductNameText>
            <SunflowerProductContainer
              marketSegment={marketSegment}
              herbicideTechnology={herbicideTechnology}
              broomrapeTechnology={broomrapeTechnology}
            />
          </ProductNameContainer>
        ) : (
          <CropProductNameContainer isDDG={isDDG} selected={isSelected} isMobile={isMobile}>
            <ProductNameText>{commercialName}</ProductNameText>
            {cropName?.toLowerCase() !== CropConstants.WHEAT_SMALLCASE && (
              <FaoContainer isMobile={isMobile}>
                <FaoText>
                  {'FAO '} {fao}
                </FaoText>
              </FaoContainer>
            )}
          </CropProductNameContainer>
        )}
        {isMobile ? (
          <HybridCollapse bordered={false} defaultActiveKey={index === 0 ? ['1'] : []} ghost={true}>
            <HybridPanel
              header={<HybridProfileText>{t('Hybrid Profile')}</HybridProfileText>}
              key="1"
            >
              {renderProductInformation()}

              <DividerContainer>
                <DividerStyled />
              </DividerContainer>

              {renderBreakoutsInformation()}
            </HybridPanel>

            {!isDDG && (
              <ViewApplicableField
                data-testid={'view-applicable-fields'}
                color={color}
                onClick={() => handleOnClick?.(productName)}
              >
                <ViewApplicableIcon>{color && <SquareSvg color={color} />}</ViewApplicableIcon>
                <ViewApplicableText color={color}>
                  {t(`View ${recommendedFieldCount} applicable Fields`)}
                </ViewApplicableText>
              </ViewApplicableField>
            )}
          </HybridCollapse>
        ) : (
          <>
            {renderProductInformation()}
            <DividerContainer>
              <DividerStyled />
            </DividerContainer>
            {renderBreakoutsInformation()}
          </>
        )}
      </CardContentContainer>
    </CardContainer>
  );
};

export default SeedCard;
