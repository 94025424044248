import React from 'react';
import { Tab, TabsContainer } from './SectionTabs.styles';
import { useTranslation } from 'react-i18next';

export type SectionTab = 'Recommendation' | 'InputAndDiagnostics';

interface SectionTabsProps {
  selectedTab: SectionTab;
  handleTabClick: (tab: SectionTab) => void;
}

const SectionTabs: React.FC<SectionTabsProps> = ({ selectedTab, handleTabClick }) => {
  const [t] = useTranslation();
  return (
    <TabsContainer>
      <Tab
        className={selectedTab === 'Recommendation' ? 'selected' : ''}
        onClick={() => handleTabClick('Recommendation')}
        data-testid="recommendation-tab"
      >
        {t('Recommendation Tab')}
      </Tab>
      <Tab
        className={selectedTab === 'InputAndDiagnostics' ? 'selected' : ''}
        onClick={() => handleTabClick('InputAndDiagnostics')}
        data-testid="input-and-diagnostics-tab"
      >
        {t('Diagnostics')}
      </Tab>
    </TabsContainer>
  );
};

export default SectionTabs;
