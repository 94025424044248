import { Helmet } from 'react-helmet';

// Facebook Pixel Code

// hack to allow gtag bypass typescript
declare global {
  interface Window {
    fbq: any;
  }
}

const ENV = 'prod';
// Add the conversion event snippet if form is submitted successfully
// Event snippet for Lead conversion page
// Run this script for production environment
export const sendConversionEventToGA = (): void => {
  const fbq = window?.fbq;
  if (process.env.REACT_APP_ENV === ENV && fbq) {
    fbq('track', 'CompleteRegistration');
  }
};
export const HeadSectionGA = (): JSX.Element => {
  // do not add script for non-production environment
  if (process.env.REACT_APP_ENV !== ENV) {
    return <></>;
  }

  return (
    <Helmet>
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '793469612815208');
          fbq('track', 'PageView');
        `}
      </script>
    </Helmet>
  );
};
