import { useEffect, useState } from 'react';
import {
  StyledHeader,
  IconLogoCrop,
  StyledDivider,
  StyledButtonHelp,
  StyledButtonLanguage,
  StyledDiv,
  StyledLanguageItems,
  StyledLanguageText,
  StyledLanguageDropdownDivider,
} from './Header.styles';

import { ConfirmationModal } from 'components/ConfirmationModal';
import IconHelp from 'components/Icons/IconHelp';
import IconLanguage from 'components/Icons/IconLanguage';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { LangDropdown } from 'components/LangDropdown';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGS } from 'base/constants/supportedLangs';
import { useFlowActions } from 'context/actions/flowActions';
import { useLocation } from 'react-router-dom';
import { useAppState } from 'context/AppState';
import { useBreakpoint } from 'hooks';
import routes from 'base/constants/routes';
import track from 'utils/amplitudeWrapper';
import HelpContactPopup from 'components/HelpContactPopup/HelpContactPopup';
import { getContactDetails } from 'utils/contactEmail';
import { OptionType } from 'context/store/flowReducer';
import { addClassByClassName, removeClassByClassName } from 'utils/helpers/helper';

interface HeaderProps {
  logoAction?: () => void;
  countryCode?: string;
}

const Header = ({ logoAction, countryCode = undefined }: HeaderProps): JSX.Element => {
  const [t, i18n] = useTranslation();
  const [currentLang, setCurrentLang] = useState('');
  const [showNeedHelpPopup, setShowNeedHelpPopup] = useState(false);
  const { setShowGoBackHomeModal, setShowPrompt } = useFlowActions();
  const flowActions = useFlowActions();
  const { pathname } = useLocation();
  const {
    flow: { showGoBackHomeModal, localCountry, optionType },
  } = useAppState();
  const { isMobile } = useBreakpoint();
  const { email, phone } = getContactDetails(countryCode ?? localCountry);

  const handleHelpButtonClick = () => {
    setShowNeedHelpPopup(true);
    addClassByClassName('syt-antd-tooltip', 'lower-z-index');
    track('open help pop up', { 'user open help': true });
    const currentRoutePath = location.pathname.toLowerCase();
    if (currentRoutePath.startsWith(routes.wizard)) {
      track('create boundary help center', {
        'map help center interaction': true,
      });
    } else if (currentRoutePath.startsWith(routes.wizardDropAPin)) {
      track('drop a pin help center', {
        'map help center interaction': true,
      });
    }
  };

  const handleClickLogo = () => {
    if (pathname !== '/' || optionType !== OptionType.empty) {
      setShowPrompt({ show: false });
      setShowGoBackHomeModal({ show: true });
    }
  };

  const handleCloseIconClick = () => {
    setShowNeedHelpPopup(false);
    removeClassByClassName('syt-antd-tooltip', 'lower-z-index');
  };

  useEffect(() => {
    const foundLanguage = SUPPORTED_LANGS.find((lang) => lang.value === i18n.language)?.shortLabel;

    setCurrentLang(foundLanguage ?? SUPPORTED_LANGS[0].shortLabel);
  }, [i18n.language]);

  return (
    <>
      <StyledHeader className={isMobile ? 'hideOnLandscape' : ''}>
        <IconLogoCrop width="140px" height="48px" onClick={logoAction ?? handleClickLogo} />
        <StyledDiv>
          <StyledButtonHelp
            icon={<IconHelp width="15px" height="15px" color={colors.neutral60} />}
            onClick={handleHelpButtonClick}
          />
          <StyledDivider />
          <LangDropdown>
            <StyledButtonLanguage>
              <StyledLanguageItems>
                <StyledLanguageText data-testid="button-language-text">
                  {currentLang}
                </StyledLanguageText>
                <StyledLanguageDropdownDivider />
                <IconLanguage width="15px" height="15px" color={colors.neutral60} />
              </StyledLanguageItems>
            </StyledButtonLanguage>
          </LangDropdown>
        </StyledDiv>
      </StyledHeader>
      {showGoBackHomeModal && (
        <ConfirmationModal
          id={'goBackHomeModal'}
          title={t('Confirm')}
          body={t(
            'Are you sure you want to navigate away from this page? All progress will be lost.'
          )}
          cancelButtonText={t('No, remain')}
          confirmButtonText={t('Yes, return home')}
          onClickCancel={() => {
            flowActions.setShowGoBackHomeModal({ show: false });
            setShowPrompt({ show: true });
          }}
        />
      )}
      {showNeedHelpPopup && (
        <HelpContactPopup countryCode={countryCode} handleCloseIconClick={handleCloseIconClick} email={email} phone={phone} />
      )}
    </>
  );
};

export default Header;
