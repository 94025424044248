/* eslint-disable array-bracket-newline */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import track from 'utils/amplitudeWrapper';
import {
  StyledSider,
  StyledContainer,
  StyledTitle,
  FieldListContainer,
  StyledButtonGroup,
  FooterButtonGroup,
  StyledParagraph,
} from './FieldInformationDrawer.styles';
import FieldForm, { useFieldForm } from './FieldForm';
import { useAppDispatch, useAppState } from 'context/AppState';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { getUniqueSoils } from 'utils/helpers/soils';
import { ActionTypes, useApiDataActions } from 'context/actions/ApiDataActions';
import FieldActionCard from 'components/FieldActionsCard';
import { useFlowActions } from 'context/actions/flowActions';
import { ModeTypes, FlowSteps, OverlappingGeometryTypes } from 'context/store/flowReducer';
import useValidation from './hooks/useValidation';
import ButtonBase from 'components/Buttons/ButtonBase';
import { pointInCountry } from 'utils/helpers/geospatial';
import { useBreakpoint } from 'hooks';
import { getFieldDefaultName } from 'utils/constants/Fields';
import IconPlus from 'components/Icons/IconPlus';
import { Field } from 'context/store/recommendationFormReducer';
import { notification } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCountryCropRegion } from 'utils/constants/CountryData';
import delay from 'utils/delay';

const FieldInformationDrawer = (): JSX.Element => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [shoudlChangeName, setShoudlChangeName] = useState(false);
  const [t] = useTranslation();
  const screens = useBreakpoint();
  const isMobile = screens.isMobile;
  const fieldSoilsCache = useRef<any[]>([]);
  const ApiDataActions = useApiDataActions();
  const flags = useFlags();
  const appDispatcher = useAppDispatch();
  const {
    flow: { currentModeType, resetBoundaryValues, previousModeType },
    recommendationForm: {
      currentFieldArea,
      previousFieldArea,
      fields,
      tempBoundaryField,
      tempFields,
      fieldSelected,
      countryCode,
      fieldNameForm,
      isAtleastAVertex,
    },
    apiData: { fieldSoils },
  } = useAppState();
  const {
    setCurrentModeType,
    setPreviousModeType,
    setResetBoundaryValues,
    setShowDeleteBoundaryModal,
    setShowCancelBoundaryModal,
    setShowEditBoundaryModal,
    setMobileActiveTab,
    setShowCropTypeDrawer,
    setFlowStep,
    setIsMapBounded,
    setAreBoundariesConfirmed,
    setShowButtonHub,
    setShowEnvironmentDrawer,
    setShowFieldForm,
    setDisabledButtonNext,
    setAddAnother,
    setShowEnvironmentPopup,
    setOverlappingGeometryType
  } = useFlowActions();
  const {
    saveField,
    setCountryCode,
    setTempBoundaryField,
    setEditingFieldIndex,
    setFieldSelectedById,
    setFieldNameForm,
    setTempFields,
    saveFields,
    setFocusFieldId,
    setFieldIdSelected,
    setAGProducts,
    setProductMadurityRange,
    deleteField: deleteFieldById,
  } = useRecommendationFormActions();
  const [fieldsBeforeEdit, setFieldsBeforeEdit] = useState<Field[] | null>(null);

  type FieldSoilKey = keyof typeof fieldSoils;

  const fieldClosingInformationText = !isAtleastAVertex
    ? '.'
    : `. ${t('in order to close the boundary click on the 1st point.')}`;

  useEffect(() => {
    setAGProducts({ agProducts: [] });
    setProductMadurityRange({ productMadurityRange: [] });
    appDispatcher({
      type: ActionTypes.setProductCatalog,
      payload: { data: [] },
    });
    appDispatcher({
      type: ActionTypes.setProductMaturityRangeFAO,
      payload: { data: {} },
    });
  }, [countryCode]);

  const getFieldDefaultValue = (): string => {
    if (tempFields.length > 0 && !currentFieldArea && currentModeType === ModeTypes.CREATING) {
      return getFieldDefaultName({ fields });
    }
    return '';
  };

  const fieldFormState = useFieldForm({
    fieldDefaultValue: getFieldDefaultValue(),
    hectaresDefaultValue: currentFieldArea !== previousFieldArea ? currentFieldArea : fieldSelected?.hectares ?? currentFieldArea,
    fields,
    isFormDisabled: !currentFieldArea && currentModeType === ModeTypes.CREATING,
  });

  const { disableForm, showFieldForm, disableNextButton } = useValidation({
    currentModeType,
    fields,
    fieldsHasErrors: fieldFormState.hasErrors.fieldName || fieldFormState.hasErrors.size,
    isBoundaryClosed: Boolean(tempBoundaryField),
  });

  useEffect(() => {
    if (
      currentModeType === ModeTypes.CREATING ||
      currentModeType === ModeTypes.SAVING ||
      (currentModeType === ModeTypes.DELETING && previousModeType === ModeTypes.EDITING)
    ) {
      fieldFormState.resetForm();
      setTempFields({
        fields: tempFields.filter((tempField) => tempField.fieldName !== fieldNameForm),
      });
    }
  }, [currentModeType]);

  const scrollToBottom = useCallback(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [bottomRef]);

  useEffect(() => {
    scrollToBottom();
  }, [showFieldForm]);

  useEffect(() => {
    setFieldNameForm({ fieldNameForm: fieldFormState.fieldName });
  }, [fieldFormState]);

  useEffect(() => {
    if (currentModeType === ModeTypes.EDITING && fieldSelected?.fieldName && shoudlChangeName) {
      fieldFormState.setFieldName(fieldSelected?.fieldName);
    }
  }, [currentModeType, fieldSelected, shoudlChangeName]);

  const trackFirstFieldSaveDuration = () => {
    const storedDate = new Date(sessionStorage.getItem('FirstBoundaryPointCreation') ?? '');
    sessionStorage.removeItem('FirstBoundaryPointCreation');
    if (Number.isNaN(storedDate.getTime())) {
      return;
    }
    const currentDate = new Date();
    const elapsedTime = (currentDate.getTime() - storedDate.getTime()) / 1000;
    if (elapsedTime < 1) {
      return;
    }
    track('time to draw first field', { 'draw field time': elapsedTime });
  };

  const saveCurrentField = useCallback(() => {
    if (!tempBoundaryField) {
      return;
    }
    const field = {
      id: tempBoundaryField.properties?.id,
      fieldName: fieldFormState.fieldName,
      hectares: fieldFormState.size,
      boundary: tempBoundaryField,
      soils: tempBoundaryField.soils,
      weather: tempBoundaryField.weather,
      selectedSoils: tempBoundaryField.selectedSoils,
      fieldsResolution: fieldSoils.fieldsResolution,
    };
    const [firstNode] = tempBoundaryField?.geometry.coordinates[0] || [];
    const [lng, lat] = firstNode;
    const countryCodePin = pointInCountry({ lng, lat }, flags);
    setCountryCode({ countryCode: countryCodePin ?? '' });
    if (currentModeType === ModeTypes.EDITING) {
      const updatedFieldList = fieldsBeforeEdit?.map((data) => {
        if (data.id === fieldSelected?.id) {
          const updatedField = {
            ...field,
            id: fieldSelected?.id,
            boundary: {
              ...field.boundary,
              properties: { ...field.boundary.properties, id: fieldSelected?.id },
            },
            soils: data.soils,
            selectedSoils: data.selectedSoils,
          };
          return updatedField;
        }
        return data;
      });
      saveFields({ fields: updatedFieldList });
    } else {
      saveField({ field });
      trackFirstFieldSaveDuration();
    }
    return [field];
  }, [tempBoundaryField, fieldFormState]);

  const handleOnSave = useCallback(async () => {
    const fieldsData = saveCurrentField();
    setCurrentModeType({ modeType: ModeTypes.SAVING });
    setAddAnother({ addAnother: false });
    setTempBoundaryField({ boundary: undefined });
    setTempFields({ fields: [] });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    setShowFieldForm({ show: false });
    setOverlappingGeometryType({ type: OverlappingGeometryTypes.NONE });
    return fieldsData;
  }, [saveCurrentField, tempBoundaryField]);

  const handleAddAnotherClick = useCallback(() => {
    if (currentModeType === ModeTypes.CREATING || currentModeType === ModeTypes.EDITING) {
      handleOnSave();
    }
    delay(200).then(() => {
      setCurrentModeType({ modeType: ModeTypes.CREATING });
      setAddAnother({ addAnother: true });
      fieldFormState.resetForm();
      setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
      setAreBoundariesConfirmed({ areBoundariesConfirmed: false });
      setMobileActiveTab({ activeTab: 'Map' });
      setShowButtonHub({ show: true });
      const element = document.querySelector('.environment-info-popup') as HTMLElement;
      if (element) {
        notification.close('environmentInfoPopup');
        setShowEnvironmentPopup({ show: false });
      }
    });
  }, [fieldFormState, resetBoundaryValues]);

  const prepareEditing = useCallback(
    ({ fieldId }: { fieldId: string | number }) => {
      const fieldIndex = fields.findIndex((_field) => _field.id.toString() === fieldId.toString());
      const field = fields[fieldIndex];
      setTempBoundaryField({ boundary: field?.boundary });
      setEditingFieldIndex({ index: fieldIndex });
      field && setTempFields({ fields: [field] });
      field?.id && deleteFieldById({ fieldId: field?.id });
    },
    [fields]
  );

  const checkBoundaryProgress = ({ fieldId }: { fieldId: string | number }) => {
    if (
      currentModeType === ModeTypes.CREATING ||
      currentModeType === ModeTypes.EDITING ||
      currentModeType === ModeTypes.CONFIRMING_AFTER_EDIT
    ) {
      setShowEditBoundaryModal({ show: true });
    } else {
      setShoudlChangeName(true);
      setCurrentModeType({ modeType: ModeTypes.EDITING });
      prepareEditing({ fieldId });
    }
  };

  const handleFieldCardOnEditClick = useCallback(
    (fieldId: string | number) => {
      setFieldsBeforeEdit(fields);
      setShoudlChangeName(false);
      setFieldSelectedById({ fieldId });
      setIsMapBounded({ bounded: false });
      checkBoundaryProgress({ fieldId });
      setFieldIdSelected({ fieldId: '' });

      setDisabledButtonNext({ disabled: true });
    },

    [checkBoundaryProgress]
  );

  const handleFieldCardOnDeleteClick = useCallback(
    (fieldId: string | number) => {
      setFieldSelectedById({ fieldId });
      setPreviousModeType({ modeType: currentModeType });
      setCurrentModeType({ modeType: ModeTypes.DELETING });
      setShowDeleteBoundaryModal({ show: true });
    },
    [currentModeType]
  );

  const openEnvironmentDrawer = async () => {
    const fieldsDrawed = fields.map((item) => ({
      id: `${item.id}`,
      geometry: {
        type: item.boundary.geometry.type,
        coordinates: [...item.boundary.geometry.coordinates],
      },
    }));

    if (fieldsDrawed.length > 0) {
      const cacheFieldGeometry = fieldsDrawed;
      await ApiDataActions.getFieldSoils(
        fieldsDrawed,
        getCountryCropRegion(countryCode),
        'SYNGENTA',
        JSON.stringify(fieldSoilsCache.current) === JSON.stringify(cacheFieldGeometry)
      );
      fieldSoilsCache.current = cacheFieldGeometry;
      setShowEnvironmentDrawer({ show: true });
      track('environment info', { 'environment info visualization': true });
    }
  };

  const handleFieldCardOnNextClick = (fieldId: string | number) => {
    setFocusFieldId({ fieldId: `${fieldId}` });
    setFieldIdSelected({ fieldId: `${fieldId}` });
    openEnvironmentDrawer();
  };

  const handleFieldCardOnClick = (fieldId: string | number) => {
    setFocusFieldId({ fieldId: `${fieldId}` });
  };

  const FieldList = useCallback(
    (fieldState: Field[]) =>
      fieldState?.map(({ fieldName, hectares, boundary, id }) => {
        const coords = boundary.geometry.coordinates as unknown as [number, number][];
        const disabled =
          [ModeTypes.CREATING, ModeTypes.EDITING, ModeTypes.CONFIRMING_AFTER_EDIT].indexOf(
            currentModeType
          ) > -1;
        return (
          <FieldActionCard
            title={fieldName}
            subtitle={`${hectares.toString()} ${t('ha')}`}
            polygonCoords={coords}
            key={`${fieldName}-${id}`}
            handleOnEditClick={() => handleFieldCardOnEditClick(id)}
            handleOnDeleteClick={() => handleFieldCardOnDeleteClick(id)}
            handleOnNextClick={() => handleFieldCardOnNextClick(id)}
            handleOnClick={() => handleFieldCardOnClick(id)}
            disabled={disabled}
            editIconTooltipLabel={t('Edit')}
            deleteIconTooltipLabel={t('Delete')}
            nextIconTooltipLabel={t('Environment Information')}
          />
        );
      }),
    [fields, currentModeType]
  );

  const verifySoils = async (fieldsData?: Field[] | undefined) => {
    let fieldInfo;
    if (fieldsData) {
      fieldInfo = fields.length >= 1 && fieldsData ? [...fields, ...fieldsData] : fieldsData;
    } else {
      fieldInfo = fields;
    }
    const areSoilsMissing = fieldInfo?.some((item) => !item.soils);
    if (areSoilsMissing) {
      const fieldsDrawed = fieldInfo?.map((item) => ({
        id: `${item.id}`,
        geometry: {
          type: item.boundary.geometry.type,
          coordinates: [...item.boundary.geometry.coordinates],
        },
      }));
      const soilsFetched =
        fieldsDrawed &&
        (await ApiDataActions.getFieldSoils(
          fieldsDrawed,
          getCountryCropRegion(countryCode),
          'SYNGENTA',
          false
        ));
      if (soilsFetched) {
        const fieldsWithSoils = fieldInfo?.map((item) => {
          const fieldId = item.id as FieldSoilKey;
          const itemSoils = soilsFetched.soilGrid[fieldId];
          let itemSelectedSoils = item.selectedSoils;
          if (!itemSelectedSoils || itemSelectedSoils?.length === 0) {
            // selecting default soils
            itemSelectedSoils = getUniqueSoils({ soils: itemSoils });
          }

          return {
            ...item,
            soils: itemSoils,
            selectedSoils: itemSelectedSoils,
            fieldsResolution: soilsFetched.fieldsResolution,
          };
        });
        if (fieldsWithSoils) {
          saveFields({ fields: [...fieldsWithSoils] });
        }
      }
    }
  };

  const onClickNext = () => {
    if (currentModeType === ModeTypes.CREATING || currentModeType === ModeTypes.EDITING) {
      handleOnSave();
    } else {
      verifySoils();
      setShowCropTypeDrawer({ show: true });
      setFlowStep({ step: FlowSteps.STEP2 });
    }
  };

  const addAnotherButton = (
    <ButtonBase
      onClick={handleAddAnotherClick}
      fullWidth={true}
      isDisabled={disableNextButton}
      buttonTestId="boundary-modal-add-another-button"
      text={t('Add Another')}
      style={{ minWidth: '116px' }}
      icon={
        <IconPlus
          width={11}
          height={11}
          viewBox="0 0 14 14"
          color={disableNextButton ? colors.neutral40 : colors.neutral60}
        />
      }
      iconAlign={'LEFT'}
      isAddAnotherButtonWeb={!isMobile}
    />
  );

  const widthStyle = useMemo(() => {
    return {
      width: 375,
    };
  }, []);

  const handelCancelClick = () => {
    setShowCancelBoundaryModal({ show: true });
  };

  const footerButtonGroupProps = useMemo(() => {
    return {
      buttonLeft: {
        isDisabled: true,
        onClick: handelCancelClick,
        buttonTestId: 'boundary-modal-cancel-button',
      },
      buttonRight: {
        isDisabled: disableNextButton,
        onClick: onClickNext,
        text: t('Next'),
        buttonTestId: 'boundary-modal-next-button',
      },
    };
  }, [disableNextButton, isMobile, onClickNext]);
  return (
    <StyledSider id="save-boundary-modal" data-testid="save-boundary-modal" {...widthStyle}>
      <StyledContainer>
        <StyledTitle>{`${t('Step {{stepNumber}}', { stepNumber: 1 })}: ${t(
          'Create Boundary'
        )}`}</StyledTitle>
        {!isMobile && (
          <StyledParagraph>
            {t('Create boundary Instructions')}
            {fieldClosingInformationText}
          </StyledParagraph>
        )}
        <FieldListContainer>{FieldList(fields)}</FieldListContainer>
        {fields.length > 0 && <Divider />}
        {showFieldForm && <FieldForm {...fieldFormState} disableForm={disableForm} />}
        {addAnotherButton}
        <div ref={bottomRef} />
      </StyledContainer>
      <StyledButtonGroup style={{ marginTop: showFieldForm || !screens.isMobile ? 'auto' : 0 }} />
      <FooterButtonGroup {...footerButtonGroupProps} />
    </StyledSider>
  );
};

export default FieldInformationDrawer;
