/* eslint-disable no-confusing-arrow */
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import layers from '../../../../../assets/icons/layers.svg';
import soil from '../../../../../assets/icons/soil.svg';
import area from '../../../../../assets/icons/area.svg';
import {
  StyledButton,
  StyledTooltip,
  TooltipContainer,
  TooltipIcon,
  TooltipRadio,
  TooltipRow,
  TooltipText,
  TooltipTitle,
} from './MapViewLayers.styles';
import { useFlowActions } from 'context/actions/flowActions';
import { useTranslation } from 'react-i18next';

interface MapViewLayersProps {
  id?: string;
  isFieldsView: boolean;
  isMobile?: boolean;
}

export const MapViewLayers = ({ id, isFieldsView, isMobile }: MapViewLayersProps): JSX.Element => {
  const [t] = useTranslation();
  const { setIsFieldsView, setShowLayerSelectionPopUp } = useFlowActions();

  const handleViewChange = (value: boolean) => {
    if (isMobile) {
      return;
    }
    setIsFieldsView({ fieldView: value });
  };

  const handleLayerButtonClick = () => {
    if (isMobile) {
      setShowLayerSelectionPopUp({ show: true });
    }
  };

  const tooltipProps = {
    title: (
      <TooltipContainer data-testid={'MapViewTooltip'}>
        <TooltipTitle>{t('Map view')}</TooltipTitle>
        <TooltipRow>
          <TooltipIcon>
            <img src={area} alt="Fields" />
          </TooltipIcon>
          <TooltipText>{t('Fields')}</TooltipText>
          <TooltipRadio
            data-testid={'fieldRadio'}
            checked={isFieldsView === true}
            onClick={() => handleViewChange(true)}
          />
        </TooltipRow>
        <TooltipRow>
          <TooltipIcon>
            <img src={soil} alt="Soil" />
          </TooltipIcon>
          <TooltipText>{t('Soil')}</TooltipText>
          <TooltipRadio
            data-testid={'soilRadio'}
            checked={isFieldsView === false}
            onClick={() => handleViewChange(false)}
          />
        </TooltipRow>
      </TooltipContainer>
    ),
    fixedTooltipLabel: true,
  };
  return (
    <StyledTooltip placement="left" color={colors.neutral90} title={t('Map View')}>
      <StyledTooltip
        placement="leftTop"
        color={colors.neutral90}
        showArrow={false}
        trigger={!isMobile ? 'click' : ''}
        getPopupContainer={(triggerNode: HTMLElement) =>
          triggerNode.parentElement ? triggerNode.parentElement : document.body
        }
        {...tooltipProps}
      >
        <StyledButton id={id} data-testid={id} onClick={handleLayerButtonClick}>
          <img src={layers} alt="Map View" />
        </StyledButton>
      </StyledTooltip>
    </StyledTooltip>
  );
};
