import {
  MainSecion,
  FieldSection,
  FieldText,
  Title,
  FieldLeftSection,
  TooltipRadio,
  FieldRightSection,
  FieldSelectionSection,
  FieldSectionBottom,
  FieldInfoCardProduct,
  StyledDivider,
  SoilInfoCardProduct,
} from './ProductAndSoilInfo.style';
import soil from '../../../../assets/images/soil.png';
import area from '../../../../assets/images/field_raw.png';
import { useTranslation } from 'react-i18next';
import { useFlowActions } from 'context/actions/flowActions';
import { useAppState } from 'context/AppState';
import ProductFieldsInfoSection from '../ProductFieldsInfoSection';
import { SoilPopData } from '../SoilCharacteristics';
import PropertySoilInfo from './PropertySoilInfo';

  interface Info {
    title: string;
    soilPopUpData?: SoilPopData[];
  }

const ProductAndSoilInfo = ({ title, soilPopUpData }: Info): JSX.Element => {
  const { t } = useTranslation();
  const { setIsFieldsView } = useFlowActions();
  const {
    flow: { selectedProductFieldInfo, isFieldsView },
  } = useAppState();
  const handleViewChange = (value: boolean) => {
    setIsFieldsView({ fieldView: value });
  };
  return (
    <>
      <Title>{title}</Title>
      <MainSecion data-testid='product-soil-info-popup'>
        <FieldSelectionSection>
          <FieldSection>
            <FieldLeftSection>
              <img src={area} alt="field" />
              <FieldText>{t('Fields')}</FieldText>
            </FieldLeftSection>
            <FieldRightSection>
              <TooltipRadio
                checked={isFieldsView === true}
                onClick={() => handleViewChange(true)}
              />
            </FieldRightSection>
          </FieldSection>
          {selectedProductFieldInfo.length > 0 && (
            <FieldSectionBottom data-testid="product-info-section">
              <FieldInfoCardProduct>
                <ProductFieldsInfoSection isMobile={true} />
              </FieldInfoCardProduct>
            </FieldSectionBottom>
          )}
        </FieldSelectionSection>
        <StyledDivider />
        <FieldSelectionSection>
          <FieldSection>
            <FieldLeftSection>
              <img src={soil} alt="field" />
              <FieldText>{t('Soil')}</FieldText>
            </FieldLeftSection>
            <FieldRightSection>
              <TooltipRadio
                checked={isFieldsView === false}
                onClick={() => handleViewChange(false)}
              />
            </FieldRightSection>
          </FieldSection>
          {!isFieldsView && (
            <FieldSectionBottom data-testid="soil-info-section">
              <SoilInfoCardProduct>
                <PropertySoilInfo data={soilPopUpData} />
              </SoilInfoCardProduct>
            </FieldSectionBottom>
          )}
        </FieldSelectionSection>
      </MainSecion>
    </>
  );
};

export default ProductAndSoilInfo;
