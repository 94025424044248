import { getUserId } from '@amplitude/analytics-browser';
import track from 'utils/amplitudeWrapper';
import ProxyLayer from 'base/api/ProxyLayer';
import { UserCategories } from 'base/types/AmplitudeClassifiers';
import { isEmail } from 'utils/validator';

export const trackStepTwoEditingStarted = (): void => {
  const editingStepTwo = sessionStorage.getItem('editingStepTwo');
  if (editingStepTwo === null) {
    sessionStorage.setItem('editingStepTwo', 'true');
  }
};

export const trackStepTwoIncomplete = (): void => {
  const editingStepTwo = sessionStorage.getItem('editingStepTwo');
  if (editingStepTwo === 'true') {
    track('user exiting without all inputs', { 'not providing all inputs': true });
    sessionStorage.removeItem('editingStepTwo');
  }
};

export const trackDrawBoundaryIncomplete = (): void => {
  const isDrawingBoundary = sessionStorage.getItem('FirstBoundaryPointCreation');
  if (isDrawingBoundary) {
    track('user not finishing field creation', { 'field creation not finished': true });
    sessionStorage.removeItem('FirstBoundaryPointCreation');
  }
};

export const trackUserCategory = (): void => {
  const amplitudeUserId = getUserId();
  if (amplitudeUserId && isEmail(amplitudeUserId)) {
    ProxyLayer.isSyngentaUser(amplitudeUserId).then(result => {
      track('user classification', {
        'user category': amplitudeUserId.includes('@syngenta.co') || result.status
          ? UserCategories.SYNGENTA_USER
          : UserCategories.NON_SYNGENTA_USER,
      });
    }).catch(() => {
      track('user classification', {
        'user category': UserCategories.UNKNOWN_USER,
      });
    });
  } else {
    track('user classification', {
      'user category': UserCategories.UNKNOWN_USER,
    });
  }
};
