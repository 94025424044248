/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
'use strict';

const allowedEnvironments = ['local', 'dev', 'qa', 'uat'];

export const isPendoEnvironmentSupported = (env: string) => allowedEnvironments.includes(env);

const pendoScript = function() {
  const pendoscript = window.pendoscript = window.pendoscript || [];
  if (!pendoscript.initialize) {
    if (pendoscript.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.');
    } else {
      pendoscript.load = function(t: any) {
        const e = document.createElement('script');
        e.type = 'text/javascript';
        e.async = !0;
        e.src = `${document.location.origin}/scripts/pendo.init.js`;

        const n = document.getElementsByTagName('script')[0];
        n?.parentNode?.insertBefore(e, n);
      };
    }
  }
  return pendoscript;
}();

export const initPendo = (environment: string) => {
  pendoScript.load(environment);
};
