import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { ModalAnimationDirections } from 'base/types/ModalBox';

import {
  StyledCard,
  StyledTitle,
  StyledParagraph,
  StyledIcon,
  StyledTextContainer,
  StyledLink,
  StyledLinkCookieSettings,
  StyledHr,
} from './HelpContactPopup.styles';
import IconHelp from 'components/Icons/IconHelp';
import { useMemo, useState } from 'react';
import { useBreakpoint } from 'hooks';
import { SouthAfricaCountryConstant } from 'utils/constants/CountryData';

interface IProps {
  handleCloseIconClick: () => void;
  email: string;
  phone?: string;
  countryCode?: string;
}

const HelpContactPopup = ({ handleCloseIconClick, email, phone, countryCode }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(
    isMobile ? ModalAnimationDirections.Up : undefined
  );

  const handleCloseButtonClick = () => {
    if (isMobile) {
      setSlidingDirection(ModalAnimationDirections.Down);
    } else {
      handleCloseIconClick();
    }
  };

  const handleAnimationEnd = () => {
    if (!isMobile || slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    handleCloseIconClick();
  };
  const showContactDetails = useMemo(() => {
    if (countryCode === SouthAfricaCountryConstant.id) {
      return (
        <StyledParagraph>
          {t('Please reach out to our area sales managers for help or queries at')}&nbsp;
          <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
        </StyledParagraph>
      );
    }
    return (
      <StyledParagraph>
        {t('Please contact our support team on')}&nbsp;
        <StyledLink href={`tel:${phone}`} className="contact-detail-span">
          {phone}
        </StyledLink>
        &nbsp;{t('Or email us at')}&nbsp;
        <StyledLink href={`mailto:${email}`}>{email}</StyledLink>&nbsp;
        {t('For help or queries. Thank you.')}
      </StyledParagraph>
    );
  }, [email, phone, countryCode]);

  return (
    <StyledCard slideDirection={slidingDirection} onAnimationEnd={handleAnimationEnd} data-testid="help-info-container">
      <CloseOutlined onClick={handleCloseButtonClick} data-testid="help-info-container-close-icon" />
      <StyledIcon>
        <IconHelp width="24px" height="24px" color={colors.blue60} />
      </StyledIcon>
      <StyledTextContainer>
        <StyledTitle>{t('Need help title')}</StyledTitle>
        {showContactDetails}
        <StyledHr />
        <StyledLinkCookieSettings
          className="contact-detail-cookie-settings"
          onClick={() => (window as any).Cookiebot.renew()}
        >
          {t('Cookie settings')}
        </StyledLinkCookieSettings>
      </StyledTextContainer>
    </StyledCard>
  );
};

export default HelpContactPopup;
