import styled from 'styled-components';
import { colors, Button, Row, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

export const Root = styled.div`
  flex: 1;
  overflow: auto;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  padding: 30px 15px;
  margin: auto;

  @media screen and (${breakpoints.maxWidth.sm}) {
    height: calc((var(--vh, 1vh) * 100) - 45px);
    padding: 24px;
  }
`;

export const StyledTitle = styled(Typography.Title)`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 45px;

  @media screen and (${breakpoints.maxWidth.sm}) {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    letter-spacing: -0.0125em;
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  max-width: 646px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 48px !important;
  color: ${colors.neutral70};

  @media screen and (${breakpoints.maxWidth.sm}) {
    margin-bottom: 0 !important;
    margin-top: 24px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    letter-spacing: -0.0075em;

    img {
      max-height: 22dvh;
    }
  }
`;

export const OutlineButton = styled(Button)`
  border: 1px solid ${colors.neutral30};
  background-color: ${colors.neutral00};
  color: ${colors.neutral100};
  width: 246px;
  height: 44px;
  font-size: 16px;

  &:hover {
    border: 1px solid ${colors.neutral30};
    background-color: ${colors.neutral00};
    color: ${colors.neutral100};
  }

  @media screen and (${breakpoints.maxWidth.sm}) {
    width: 100%;
    margin-top: auto;
  }
`;

export const StyledLink = styled.a`
  color: ${colors.blue60};
  text-decoration: underline !important;
  font-weight: 400 !important;
`;

export const StyledPrimaryButtonRow = styled(Row)`
  gap: 24px;

  button {
    width: 273px;
    height: 44px;
    padding: 10px 16px;
  }

  @media screen and (${breakpoints.maxWidth.sm}) {
    gap: 16px;
    flex-direction: row;
    width: 100%;
    padding-top: 16px;
    margin-top: auto;
    justify-content: flex-start;

    button {
      width: 100%;
    }
  }
`;
