import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { YieldData } from 'base/types/BreakoutsGlobalResponse';
import {
  StyledRow,
  StyledDivText,
  StyledDivValue,
  StyledModalView,
} from './TrialingInformationModal.styles';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { useAppState } from 'context/AppState';
import { AgronomicInputs } from 'base/types/RecommendationCropwise';
import { useFlowActions } from 'context/actions/flowActions';
import { CropConstants } from 'utils/constants/Crop';

interface TrialingInformationModalProps {
  info: YieldData;
  onClose: () => void;
}

const TrialingInformationModal = (props: TrialingInformationModalProps) => {
  const { info, onClose } = props;
  const { t } = useTranslation();
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(ModalAnimationDirections.Up);
  const {
    setIsModalToBeClosed,
  } = useFlowActions();
  const {
    flow: { isModalToBeClosed },
    apiData: { recommendationCropwise },
  } = useAppState();
  const [agronomicInputs, setAgronomicInputs] = useState<AgronomicInputs>();

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const agroInput =
        recommendationCropwise.recommendations[0]?.multi_field?.config?.agronomicInputs;
      setAgronomicInputs(agroInput);
    }
  }, [recommendationCropwise]);

  useEffect(() => {
    if (isModalToBeClosed) {
      setIsModalToBeClosed({ isModalToBeClosed: false });
      setSlidingDirection(ModalAnimationDirections.Down);
    }
  }, [isModalToBeClosed, setIsModalToBeClosed]);

  const handleAnimationEnd = () => {
    if (slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    onClose();
  };

  const metricLabel = useMemo(() => {
    if (recommendationCropwise?.country === CropConstants.Brazil) {
      return '';
    } else {
      return agronomicInputs?.extraInfo?.yieldRangeUnit ?? '(-)';
    }
  }, [agronomicInputs?.extraInfo?.yieldRangeUnit, recommendationCropwise?.country]);

  return (
    <StyledModalView
      isViewFooter
      showHeaderRectangle
      onCancel={onClose}
      width={490}
      title={info.commercialName}
      content={
        <>
          <StyledRow>
            <StyledDivText>{t('Average yield results in the trial')}</StyledDivText>
            <StyledDivValue>
              {info.avgCheckYield} {metricLabel}
            </StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>
              {info.commercialName} {t('yield result')}
            </StyledDivText>
            <StyledDivValue>
              {info.avgEntryYield} {metricLabel}
            </StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>{t('Absolute yield difference')}</StyledDivText>
            <StyledDivValue>
              {info.yieldDiff} {metricLabel}
            </StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>{t('Relative yield difference')}</StyledDivText>
            <StyledDivValue>{info.yieldDiffPercentage}%</StyledDivValue>
          </StyledRow>
          <StyledRow>
            <StyledDivText>{t('Trial Year')}</StyledDivText>
            <StyledDivValue>{info.trialYear}</StyledDivValue>
          </StyledRow>
        </>
      }
      slideDirection={slidingDirection}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default TrialingInformationModal;
