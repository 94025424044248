export const HyperLinkConstants = [
  {
    countryCode: 'ROU',
    link: 'https://www.syngenta.ro/cataloage-digitale',
    linkName: 'Catalog semințe culturi de câmp 2025',
  },
  {
    countryCode: 'HUN',
    link: 'https://www.syngenta.hu/sites/g/files/kgtney1461/files/media/document/2023/09/28/Syngenta_2024_kukorica_napraforgo_vetomag_cp.pdf',
    linkName: 'Kukorica és napraforgó hibrid kínálat és technológia 2024',
  },
  {
    countryCode: 'POL',
    link: 'https://www.syngenta.ro/catalog-seminte-culturi-de-camp-2023',
    linkName: 'Catalog semințe culturi de câmp 2023',
  },
];
