import ModalView from 'components/ModalView';
import breakpoints from 'base/constants/breakpoints';
import { colors, SYT_PREFIX, Typography, Row, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';
import ButtonBase, { EButtonType } from 'components/Buttons/ButtonBase';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';

const { Paragraph } = Typography;

export const StyledModalView = styled(ModalView)<IStyledModalProps>`
  .${SYT_PREFIX}-modal-content {
    border-radius: 8px;
  }
  ${(props) => showSlidingAnimation(props.slideDirection)}
  @media screen and (max-height: 769px) {
    .${SYT_PREFIX}-modal-content {
      top: 56px !important;
      overflow: auto !important;
    }
  }
  .${SYT_PREFIX}-modal-header {
    border-radius: 8px;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;

  /* Neutral / neutral-70 */
  color: ${colors.neutral70};

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: 16px;
`;

export const StyledButtonRow = styled(Row)`
  @media screen and (${breakpoints.maxWidth.md}) {
    margin-top: auto;
    bottom: 0px;
  }

  @media screen and (${breakpoints.maxWidth.xs}) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledButton = styled(ButtonBase)`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.0075em;

  color: ${(props) => props.type === EButtonType.default && `${colors.neutral100}`};

  @media screen and (${breakpoints.maxWidth.sm}) {
    background-color: ${(props) => props.type === EButtonType.default && '#fff'};
    border: ${(props) => props.type === EButtonType.default && `1px solid ${colors.neutral30}`};
  }
`;

export const StyledAnchor = styled.a`
  color: ${colors.green60};

  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: -0.0075em;
`;

export const StyledSpanText = styled.span`
  color: ${colors.neutral60};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: -0.0075em;
`;

export const StyledCheckboxContainer = styled.div`
  padding-bottom: 16px;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColButtonStyled = styled(Col)`
  @media screen and (${breakpoints.maxWidth.md}) {
    margin-bottom: 16px;
  }
`;

export { ColButtonStyled };
