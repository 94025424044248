import { ProductSeed } from 'base/types/Products';

export const convertSeedsToBagsForProduct = (
  seedingRate: number,
  areaApplied: number,
  product: ProductSeed | undefined
): number => {
  if (product && product.sku.length > 0) {
    const sku = product.sku[0]?.bagSize ? product.sku[0] : product.sku[1];
    if (!sku?.bagSize) {
      return 0;
    };
    const bagSize = Number(sku.bagSize);

    return bagSize === 0 ? bagSize : (seedingRate * areaApplied) / bagSize;
  }
  return 0;
};
