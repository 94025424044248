import { TooltipProps } from 'antd';
import styled from 'styled-components';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';

export interface ButtonProps {
  isDisabled?: boolean;
  backgroundColor?: string;
  borderRadius?: string;
}

export const StyledButton = styled.button<ButtonProps>`
  border: none;
  width: 40px;
  height: 40px;
  border-radius: ${({ borderRadius }) => borderRadius ?? '50%'};
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#f5f5f5'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
`;

type TooltipCustomProps = TooltipProps & { fixedTooltipLabel?: boolean };

export const StyledTooltip = styled(Tooltip)<TooltipCustomProps>`
  & + div {
    left: ${(props) => (props.fixedTooltipLabel ? 'unset !important;' : '')};
    right: ${(props) => (props.fixedTooltipLabel ? '21px;' : '')};
    pointer-events: none;
  }
`;
