import { useCallback, useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react';
import { colors, Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import { Map as MapReact, useMap, Marker as MarkerReact, Source, Layer } from 'react-map-gl';
import lookup from 'country-code-lookup';
import { LngLat, Popup } from 'mapbox-gl';
import { useTranslation } from 'react-i18next';
import {
  clusterLayer,
  clusterCountLayer,
  TrialingInfoPinIcon,
  unclusteredPointLayerIcon,
} from './layers';

import { CustomControl } from 'components/Maps/Controls/CustomControl';
import { ZoomControl } from 'components/Maps/Controls/ZoomControl';
import IconClose from 'components/Icons/IconClose';
import {
  MapContainer,
  StyledOverlay,
  StyledButton,
  StyledDiv,
  StyledDivProducts,
  StyledCloseContainer,
  SectionContainerStyled,
  StyledUserMarker,
  StyledNotificationContent,
  StyledSpinner,
} from './TrialingInformation.styles';
import NotificationTextTranslation from 'components/NotificationTextTranslation';
import ProductsTrialed from 'components/ProductsTrialed';
import { LocationControl } from 'components/Maps/Controls/LocationControl';
import {
  TrialingContent as SectionContent,
  TrialingInformationTitle as Title,
  Text,
} from 'pages/RecommendationV2/V2Common.styles';
import { AgronomicInputs, TrialProduct } from 'base/types/RecommendationCropwise';
import { YieldData } from 'base/types/BreakoutsGlobalResponse';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { browserDetector } from 'utils/helpers/browser';
import { BrowserConstants } from 'utils/constants/BrowserConstants';
import openMapNotification from 'utils/openMapNotification';
import { pointInBbox, getCentroidByCountry, getZoomLevelByCountry } from 'utils/helpers/geospatial';
import { useAppState } from 'context/AppState';
import debounce from 'utils/debounce';
import delay from 'utils/delay';
import track from 'utils/amplitudeWrapper';
import { useBreakpoint } from 'hooks';
import {
  SkeletonRowStyled,
  SkeletonTitleStyled,
  SkeletonMobileContainer,
  SkeletonMobileBottomContainer,
  SkeletonMobileTopContainer,
} from './SoilCharacteristics.styles';
import TrialingInformationModal from './TrialingInformationModal';

interface TrialindInformationProps {
  sectionRef?: any;
  isLoading?: boolean;
  isHumanUser?: boolean;
}

const browserDetected = browserDetector();
let locationURL = BrowserConstants.CHROME_LOCATION_URL;
switch (browserDetected) {
  case BrowserConstants.FIREFOX:
    locationURL = BrowserConstants.FIREFOX_LOCATION_URL;
    break;
  case BrowserConstants.SAFARI:
    locationURL = BrowserConstants.SAFARI_LOCATION_URL;
    break;
  case BrowserConstants.EDGE:
    locationURL = BrowserConstants.EDGE_LOCATION_URL;
    break;
  case BrowserConstants.OPERA:
    locationURL = BrowserConstants.OPERA_LOCATION_URL;
    break;
}

export default function TrialingInformation(props: TrialindInformationProps) {
  const { sectionRef, isLoading, isHumanUser } = props;
  const { trialingMap: map } = useMap();
  const mapContainerRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const [products, setProducts] = useState<TrialProduct[] | null>();
  const [productLocations, setProductLocations] = useState({});
  const [markersCoordinates, setMarkersCoordinates] = useState<YieldData[] | null>();
  const [intersectLocation, setIntersectLocation] = useState(false);
  const [showUserMarker, setShowUserMarker] = useState(false);
  const [latitudeUser, setLatitudeUser] = useState(MapsConstants.LATITUDE_DEFAULT);
  const [longitudeUser, setLongitudeUser] = useState(MapsConstants.LONGITUDE_DEFAULT);
  const [userLocation, setUserLocation] = useState<LngLat | null>();
  const [showNotification, setShowNotification] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [showProducts, setShowProducts] = useState(false);
  const [trialedDetail, setTrialedDetail] = useState<YieldData | null>();
  const { isMobile, landscape, isDesktop } = useBreakpoint();
  const [agronomicInputs, setAgronomicInputs] = useState<AgronomicInputs>();
  const popupRef = useRef<Popup>(
    new Popup({
      closeOnClick: false,
      closeButton: true,
      maxWidth: '440px',
      offset: [0, -18],
      focusAfterOpen: false,
    })
  );
  const [t] = useTranslation();
  const {
    apiData: { recommendationCropwise, breakoutsGlobal, productCatalog },
  } = useAppState();
  const isUserNotYetVerified = isHumanUser === undefined;

  type ProductLocationKey = keyof typeof productLocations;

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const agroInput =
        recommendationCropwise.recommendations[0]?.multi_field?.config?.agronomicInputs;
      setAgronomicInputs(agroInput);
    }
  }, [recommendationCropwise]);

  const mapInititalState = {
    zoom: MapsConstants.ZOOM_DEFAULT,
    latitude: MapsConstants.LATITUDE_DEFAULT,
    longitude: MapsConstants.LONGITUDE_DEFAULT,
  };

  const zoomIn = () => {
    map?.getMap().zoomIn();
    track('recommendation interaction', { 'map interaction zoom': 'zoomIn' });
    isMobile && track('recommendation interaction', { 'cellphone map interaction': true });
  };

  const zoomOut = () => {
    map?.getMap().zoomOut();
    track('recommendation interaction', { 'map interaction zoom': 'zoomOut' });
    isMobile && track('recommendation interaction', { 'cellphone map interaction': true });
  };

  const onClickMarkerHandler = (yieldData: YieldData) => {
    track('recommendation interaction', { 'map interaction pin': 'opened' });
    isMobile && track('recommendation interaction', { 'cellphone map interaction': true });
    if (!isMobile && map) {
      const html = `
      <div>
        <span>${t('Average yield results in the trial')}</span>
        <span class="popup-value"><b>${yieldData.avgCheckYield}</b> ${
  agronomicInputs?.extraInfo?.yieldRangeUnit ?? '(-)'
}</span>
      </div>
      <div>
        <span> ${yieldData.commercialName} ${t('yield result')}</span>
        <span class="popup-value"><b>${yieldData.avgEntryYield}</b> ${
  agronomicInputs?.extraInfo?.yieldRangeUnit ?? '(-)'
}</span>
      </div>
      <div>
        <span>${t('Absolute yield difference')}</span>
        <span class="popup-value"><b>${yieldData.yieldDiff}</b> ${
  agronomicInputs?.extraInfo?.yieldRangeUnit ?? '(-)'
}</span>
      </div>
      <div>
        <span>${t('Relative yield difference')}</span>
        <span class="popup-value"><b>${yieldData.yieldDiffPercentage}</b>%</span>
      </div>
      <div>
        <span>${t('Trial Year')}</span>
        <span class="popup-value"><b>${yieldData.trialYear}</b></span>
      </div>
    `;

      popupRef.current
        .setLngLat({ lng: yieldData.longitude, lat: yieldData.latitude })
        .setHTML(html);

      // fix the issue that the popup was closed right after open it
      setTimeout(() => popupRef.current.addTo(map.getMap()), popupRef.current.isOpen() ? 0 : 50);
    } else {
      setTrialedDetail(yieldData);
    }
  };

  const convertToGeoJSON = (data: any): any => {
    const features = data.map((item: any) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
        properties: item,
      };
    });
    return {
      type: 'FeatureCollection',
      features: features,
    };
  };

  const markersGeoJson = convertToGeoJSON(markersCoordinates || []);

  const onLoadMapHandler = () => {
    setIsMapLoaded(true);
    map?.addControl(new CustomControl('zoomControlTrialing'), 'bottom-left');
    map?.addControl(new CustomControl('locationControlTrailing'), 'bottom-left');
    map?.addControl(new CustomControl('closeControl'), 'top-left');
    map?.addControl(new CustomControl('productsControl'), 'top-left');

    map?.on('idle', () => {
      setShowSpinner(false);
    });

    map?.on('click', 'unclustered-point', (e) => {
      const features = map?.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] });
      if (features && features.length > 0 && features[0]?.properties) {
        onClickMarkerHandler(features[0]?.properties as YieldData);
      }
    });
    map?.on('click', 'clusters', (e) => {
      const features = map?.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      if (features && features.length > 0 && features[0]?.properties && features[0].geometry) {
        const zoomLevel = map?.getZoom();
        map.easeTo({
          center: [e.lngLat.lng, e.lngLat.lat],
          zoom: zoomLevel + 1,
        });
      }
    });

    map?.loadImage(`data:image/png;base64,${TrialingInfoPinIcon}`, (error, image) => {
      if (error) {
        throw error;
      }
      map?.addImage('custom-marker-pin', image as HTMLImageElement);
    });
    map?.getMap().setMaxZoom(MapsConstants.ZOOM_IN_LIMIT_TRIALING_INFO_MAP);
  };

  const onClickProductHandler = async (index: number) => {
    if (selectedProduct === index) {
      return;
    }

    if (products && productLocations) {
      const productName = products[index].productName;
      track('recommendation interaction', { 'map interaction product': productName });
      isMobile && track('recommendation interaction', { 'cellphone map interaction': true });

      popupRef.current?.remove();
      setShowSpinner(true);
      setSelectedProduct(index);
      setMarkersCoordinates([]);
      await delay(50);
      const markers: any[] = productLocations[productName as ProductLocationKey];

      if (markers.length) {
        setMarkersCoordinates(markers);
      } else {
        setShowSpinner(false);
      }
    }
  };

  const checkIntersection = () => {
    if (!userLocation) {
      return;
    }
    // eslint-disable-next-line
    setIntersectLocation(pointInBbox(userLocation, map!.getBounds()));
  };

  const onLocationClick = () => {
    setIsLocationEnabled(true);
  };

  const onLocationErrorHandler = () => {
    if (!showNotification) {
      return;
    }

    openMapNotification({
      id: 'preciseLocation',
      className: 'toast-notification-without-icon',
      msg: (
        <StyledNotificationContent>
          <NotificationTextTranslation text={'Your precise location could not be determined.'} />{' '}
          <a target="_blank" rel="noreferrer" href={locationURL}>
            <NotificationTextTranslation text={'LEARN MORE'} />
          </a>
        </StyledNotificationContent>
      ),
      placement: 'bottom',
      duration: 10,
      width: 480,
      closeIcon: <IconClose color={colors.neutral40} width={18} height={18} />,
      onClose: () => setShowNotification(true),
      icon: <></>,
      container: document.getElementById('trialingMap'),
    });
    setShowNotification(false);
  };

  const fitBounds = useCallback(() => {
    if (recommendationCropwise?.country) {
      const code = lookup.byCountry(recommendationCropwise.country);
      const countryCodeISO3 = code?.iso3 ?? '';
      const countryCentroid = getCentroidByCountry(countryCodeISO3);
      if (countryCentroid) {
        const {
          geometry: { coordinates: center },
        } = countryCentroid;
        const zoomLevel = getZoomLevelByCountry(countryCodeISO3);
        map?.flyTo({
          center: [center[0], center[1]],
          essential: true,
          zoom: zoomLevel,
        });
      }
    }
  }, [map, recommendationCropwise?.country]);

  useLayoutEffect(() => {
    if (!isHumanUser) {
      return;
    }
    function closePopup() {
      if (!popupRef.current.isOpen()) {
        return;
      }

      const ele: any = document.querySelector('.mapboxgl-popup-close-button');
      ele?.click?.();
    }

    map?.on('click', closePopup);

    return () => {
      map?.off('click', closePopup);
    };
  }, [isHumanUser, map]);

  useLayoutEffect(() => {
    if (!isHumanUser) {
      return;
    }
    const mapRef = map?.getMap();
    const resizer = new ResizeObserver(
      debounce(() => {
        // Recenter the map on any container size change
        mapRef?.resize();
      })
    );

    if (mapContainerRef.current) {
      resizer.observe(mapContainerRef.current);
    }

    return () => {
      resizer.disconnect();
    };
  }, [map, isHumanUser]);

  useEffect(() => {
    if (recommendationCropwise?.recommendations) {
      const productsInfo = recommendationCropwise?.recommendations[0]?.products ?? [];
      const trialedProducts = productsInfo
        .filter((seed) => seed.product_name !== 'None')
        .map((recommendedProduct) => {
          const product = productCatalog?.find(
            (p) => p.productName === recommendedProduct.product_name
          );
          return {
            productName: product?.productName,
            commercialName: product?.commercialName,
          };
        }) as TrialProduct[];

      setProducts(trialedProducts);

      if (breakoutsGlobal && trialedProducts.length) {
        trialedProducts.forEach((item, index) => {
          // processing product locations
          if (breakoutsGlobal[item.productName]) {
            const productBreakouts = breakoutsGlobal[item.productName];
            const { locationsYieldData } = productBreakouts;
            const AVG_CHECK_YIELD_DISPLAY_RULE =
              agronomicInputs?.extraInfo?.yieldRangeUnit === 'q/ha' ? 20 : 2; // Exclude all the yields bellow 2 t/ha (20 q/ha) from result map
            const locations = locationsYieldData
              .map(
                (yieldItem) =>
                  ({
                    longitude: yieldItem.LONGITUDE,
                    latitude: yieldItem.LAT,
                    commercialName: item.commercialName,
                    avgCheckYield: yieldItem.AVG_CHECK_YIELD,
                    avgEntryYield: yieldItem.AVG_ENTRY_YIELD,
                    yieldDiff: yieldItem.YIELD_DIFF,
                    yieldDiffPercentage: yieldItem.YIELD_DIFF_PERCENTAGE,
                    trialYear: yieldItem.TRIAL_YEAR,
                  } as YieldData)
              )
              .filter((item) => {
                if (recommendationCropwise.country !== 'BR') {
                  return (
                    item.latitude &&
                    item.longitude &&
                    item.avgCheckYield &&
                    item.avgCheckYield >= AVG_CHECK_YIELD_DISPLAY_RULE
                  );
                } else {
                  return item;
                }
              });
            setProductLocations((prevState) => ({
              ...prevState,
              [item.productName]: locations,
            }));
            if (index === 0) {
              setMarkersCoordinates(locations);
              fitBounds();
            }
          }
        });
      }
    }
    /* eslint-disable-next-line */
  }, [recommendationCropwise, breakoutsGlobal, fitBounds]);

  const onUserLocatedHandler = (lngLat: LngLat) => {
    if (map) {
      setLongitudeUser(lngLat.lng);
      setLatitudeUser(lngLat.lat);
      setShowUserMarker(true);
      setUserLocation(lngLat);
      if (isMobile && markersCoordinates) {
        const allCoordinates = markersCoordinates.length > 0 ? [...markersCoordinates] : [];
        allCoordinates.push({ latitude: lngLat.lat, longitude: lngLat.lng });

        fitBounds();
      } else {
        map.flyTo({
          center: [lngLat.lng, lngLat.lat],
          essential: true,
          zoom: 15,
        });
      }
    }
  };

  const showControls = (isMobile && showProducts) || !isMobile;

  const onViewInformationHandler = () => {
    setShowProducts(true);

    if (isMobile && !landscape) {
      setIsLocationEnabled(true);
    }
  };

  const onCloseInformationHandler = () => {
    setShowProducts(false);

    const closeButtons = Array.from(
      document.querySelectorAll('.syt-antd-notification-notice-close')
    );
    closeButtons.forEach((closeButton) => {
      const element = closeButton as HTMLElement;
      const spanElement = element?.firstChild as HTMLElement;
      spanElement.click();
    });
  };

  const onCloseTrialedDetailHandler = () => {
    setTrialedDetail(null);
  };

  const SkeletonLoader = useMemo(() => {
    if (isMobile) {
      return (
        <SkeletonMobileContainer>
          <SkeletonMobileTopContainer>
            <SkeletonTitleStyled width="80%" active size="large" />
          </SkeletonMobileTopContainer>
          <SkeletonMobileBottomContainer>
            <SkeletonRowStyled active size="large" />
            <SkeletonRowStyled active size="large" />
            <SkeletonRowStyled active size="large" />
          </SkeletonMobileBottomContainer>
        </SkeletonMobileContainer>
      );
    }
    return <Skeleton active paragraph={{ rows: 11, width: '100%' }} />;
  }, [isMobile]);

  // Hide the trialing info section if the user is a bot. Show loader if the user is not yet verified.
  return isHumanUser === false ? null : (
    <SectionContainerStyled ref={sectionRef}>
      <SectionContent>
        <Title>{t('Trialing Information')}</Title>
        <Text data-testid="trialing description">{t('Trialing Information description')}</Text>
      </SectionContent>
      {isUserNotYetVerified || isLoading ? (
        SkeletonLoader
      ) : (
        <MapContainer
          id="map-container"
          fullScreen={showProducts}
          ref={mapContainerRef}
          isMobile={isMobile}
        >
          <MapReact
            id="trialingMap"
            scrollZoom={false}
            mapStyle={MapsConstants.MAP_STYLE_URL}
            initialViewState={mapInititalState}
            onLoad={onLoadMapHandler}
            minZoom={1}
            onMove={checkIntersection}
          >
            {isMobile && !showProducts && (
              <StyledOverlay>
                <StyledButton onClick={onViewInformationHandler}>
                  {t('View Trialing Information')}
                </StyledButton>
              </StyledOverlay>
            )}
            <div
              id="mapContainer"
              data-testid="mapContainer"
              style={{ display: isMapLoaded ? 'block' : 'none' }}
            >
              <StyledDiv id="zoomControlTrialing" className={showControls ? '' : 'hidden'}>
                <ZoomControl
                  buttonPlusProps={{ 'aria-label': 'Zoom In', onClick: zoomIn }}
                  buttonMinusProps={{ 'aria-label': 'Zoom Out', onClick: zoomOut }}
                />
              </StyledDiv>

              <StyledCloseContainer id="closeControl" className={showProducts ? '' : 'hidden'}>
                <IconClose
                  data-testid="closeControl"
                  color={colors.neutral00}
                  width={20}
                  height={20}
                  onClick={onCloseInformationHandler}
                />
              </StyledCloseContainer>

              <StyledDivProducts
                id="productsControl"
                isMobile={isMobile}
                className={showControls ? '' : 'hidden'}
              >
                <ProductsTrialed products={products || []} onClick={onClickProductHandler} />
              </StyledDivProducts>

              <StyledDiv
                data-testid="locationControl"
                id="locationControlTrailing"
                className={showControls ? '' : 'hidden'}
              >
                <LocationControl
                  buttonProps={{
                    'aria-label': 'Find my location',
                  }}
                  onUserLocated={onUserLocatedHandler}
                  onLocationError={onLocationErrorHandler}
                  isLocationEnabled={isLocationEnabled}
                  mapIntersected={intersectLocation}
                  onClick={onLocationClick}
                />
              </StyledDiv>
            </div>
            <Source
              id="trialingMapSource"
              type="geojson"
              data={markersGeoJson}
              cluster={true}
              clusterMaxZoom={4}
              clusterMinPoints={4}
              clusterRadius={30}
            >
              <Layer {...unclusteredPointLayerIcon} />
              <Layer {...clusterLayer} />
              <Layer {...clusterCountLayer} />
            </Source>
            {showUserMarker && (
              <MarkerReact style={{ zIndex: 1 }} latitude={latitudeUser} longitude={longitudeUser}>
                <StyledUserMarker />
              </MarkerReact>
            )}
            {trialedDetail && (
              <TrialingInformationModal
                info={trialedDetail}
                onClose={onCloseTrialedDetailHandler}
              />
            )}
          </MapReact>
          {showSpinner && <StyledSpinner />}
        </MapContainer>
      )}
    </SectionContainerStyled>
  );
}
