import { ICrop } from 'base/types/Crop';
import { DownyMildewResponse } from 'base/types/DownyMildew';
import { AttributesForFields, TPPForFields } from 'base/types/Fields';
import { ProductSeedCompatibleForCrops } from 'base/types/Products';
import { DDLOptions } from 'context/store/recommendationFormReducer';
import { TFunction } from 'i18next';
import { CropConstants } from 'utils/constants/Crop';

const isTPPFilterRequired = true;

export interface AGInput {
  tppData: TPPForFields;
  downyMildew?: DownyMildewResponse;
  selectedCrop: ICrop | undefined;
}

interface AgronomicInputs {
  productMadurityRange: [number, number] | null | number[];
  yieldRangeId: string;
  yieldRangeLabel: string;
  plantingDate: moment.Moment | null | string;
  harvestDate: moment.Moment | null;
  areFieldsIrrigated: boolean;
  agProducts: ProductSeedCompatibleForCrops[];
  tillagePractice: string;
  rotationIntensity: string;
  herbicideTraitOptions: DDLOptions[];
  broomrapeOptions: DDLOptions[];
  selectedHerbicideTrait: DDLOptions[];
  selectedBroomrape: string[];
  isBroomrapeInfestation: boolean;
  selectedSegment: string[];
  adjustedProductMaturityRange: [number, number] | null;
  productCountBasedOnUserSelectedFAO: number;
}

export interface ProductListEmptyReponse {
  errorMsg: string;
  isProductListEmpty: boolean;
}

const getSunflowerProductList = (otherAgInput: AGInput, agronomicInputs: AgronomicInputs) => {
  let filteredProducts: ProductSeedCompatibleForCrops[];
  // removed herbicide trait while filtering the product as it is optional now for Sunflower crop.
  filteredProducts = agronomicInputs.agProducts.filter((product: ProductSeedCompatibleForCrops) =>
    agronomicInputs.selectedSegment?.includes(product.segment)
  );
  if (isTPPFilterRequired && otherAgInput.tppData?.products.length > 0) {
    // Products After TPP Product Filteration
    filteredProducts = filteredProducts.filter((product) =>
      otherAgInput.tppData.products?.includes(product.productName)
    );
    // Products After DM Filteration
    if (otherAgInput.downyMildew?.value?.length) {
      filteredProducts = filteredProducts.filter((product) =>
        otherAgInput.downyMildew?.value?.includes(product.downyMildew)
      );
    }
  } else if (otherAgInput.downyMildew?.value?.length) {
    filteredProducts = filteredProducts.filter((product) =>
      otherAgInput.downyMildew?.value?.includes(product.downyMildew)
    );
  }
  if (agronomicInputs?.isBroomrapeInfestation) {
    filteredProducts = filteredProducts.filter((product) => (agronomicInputs.selectedBroomrape[0] === 'F+'
      ? product.broomrape?.replace('BR ', '') === 'F' ||
      product.broomrape?.replace('BR ', '') === 'E'
      : agronomicInputs.selectedBroomrape[0] === product.broomrape?.replace('BR ', ''))
    );
  }
  if (agronomicInputs.selectedHerbicideTrait.length > 0) {
    const traitValues = agronomicInputs.selectedHerbicideTrait.map((item) => item.value);
    filteredProducts = filteredProducts.filter((product) => {
      return traitValues.some(traitValue => traitValue.includes(product.trait));
    });
  }
  return filteredProducts;
};

const getProductNames = (productList: any) => {
  const productNames = productList.map((product: any) => {
    return product?.productName;
  });
  return productNames;
};

const getProductListBasedOnAttributes = (
  products: ProductSeedCompatibleForCrops[],
  fieldsAttributes?: AttributesForFields
) => {
  if (fieldsAttributes?.region_id) {
    return products.filter((item) => item.meso === fieldsAttributes.region_id);
  } else {
    return products;
  }
};

export const getProductList = (
  otherAgInput: AGInput,
  agronomicInputs: AgronomicInputs,
  countryCode: string | undefined,
  fieldsAttributes?: AttributesForFields
): string[] => {
  let productNamesList: string[];
  if (otherAgInput?.selectedCrop?.id === CropConstants.SUNFLOWER_CROP_ID) {
    const filteredProducts = getSunflowerProductList(otherAgInput, agronomicInputs);
    productNamesList = getProductNames(filteredProducts);
  } else if (countryCode === CropConstants.BRAZIL_CODE) {
    const filteredProducts = getProductListBasedOnAttributes(
      agronomicInputs.agProducts,
      fieldsAttributes
    );
    productNamesList = getProductNames(filteredProducts);
  } else {
    productNamesList = getProductNames(agronomicInputs.agProducts);
  }
  return productNamesList;
};

export const checkIfProductListIsEmpty = (
  otherAgInput: AGInput,
  agronomicInputs: AgronomicInputs,
  t: TFunction,
  countryCode: string | undefined,
  fieldsAttributes: AttributesForFields
): ProductListEmptyReponse => {
  const { selectedHerbicideTrait, selectedBroomrape } = agronomicInputs;
  const isProductListEmpty = getProductList(
    otherAgInput,
    agronomicInputs,
    countryCode,
    fieldsAttributes
  )?.length
    ? false
    : true;
  let isFPlusCompatibleHTSelected = false;
  const traitValues = selectedHerbicideTrait?.map((item: DDLOptions) => item.value);
  if (traitValues?.includes('IMI,IMI+')) {
    isFPlusCompatibleHTSelected = true;
  } else if (!isFPlusCompatibleHTSelected && traitValues?.includes('AIR')) {
    isFPlusCompatibleHTSelected = true;
  } else {
    isFPlusCompatibleHTSelected = false;
  }

  if (selectedBroomrape[0] === 'F+' && !isFPlusCompatibleHTSelected) {
    return {
      errorMsg: t(
        'Our products don`t match selected criteria. Try selecting “Clearfield/ClearfieldPlus” herbicide technology instead.'
      ),
      isProductListEmpty: true,
    };
  } else {
    return {
      errorMsg: t(
        'No products satisfy the provided criteria. Please adjust your inputs to get the recommendation.'
      ),
      isProductListEmpty: isProductListEmpty,
    };
  }
};
