import { Sku } from 'base/types/RecommendationCropwise';
import field_area from '../../../../assets/icons/field_area.svg';
import qr_code from '../../../../assets/icons/qr_code.svg';
import { useTranslation } from 'react-i18next';
import {
  IconText,
  ProductContainer,
  CircleContainer,
  InnerCircle,
  ProductNameText,
  IconContainer,
  ProductContainerRow,
  ProductCards,
  TopRatedProduct,
  ProductFieldDetails,
} from './RecommendationProductsCard.styles';

export interface IProps {
  index: number;
  id?: string;
  color?: string;
  product_name: string;
  commercial_name: string;
  applied_area?: number;
  quantity?: number;
  handleOnClick?: (productName: string) => void;
  isProductSelected?: boolean;
  isMobile?: boolean;
  size?: number;
  sku?: Sku;
  isDDG?: boolean;
  isAlternateProduct?: boolean;
}

const RecommendationProductsCard = ({
  index,
  id,
  color,
  product_name,
  commercial_name,
  applied_area,
  quantity,
  sku,
  handleOnClick,
  isProductSelected,
  isMobile,
  size,
  isDDG,
  isAlternateProduct
}: IProps) => {
  const [t] = useTranslation();
  return (
    <ProductCards onClick={() => !isAlternateProduct && handleOnClick?.(product_name)}>
      <ProductContainer size={size} id={id} isProductSelected={isProductSelected}>
        {isDDG && index === 0 && <TopRatedProduct>{t('Top Rated Product')}</TopRatedProduct>}
        {isAlternateProduct && <TopRatedProduct isAlternateProduct>{t('Alternative Product')}</TopRatedProduct>}
        <ProductContainerRow>
          <CircleContainer color={color}>
            <InnerCircle color={color} />
          </CircleContainer>
          <ProductNameText>{commercial_name}</ProductNameText>
        </ProductContainerRow>
        <ProductFieldDetails isAlternateProduct={isAlternateProduct} >
          {sku && Object.entries(sku).length > 0 && sku.available && (
            <ProductContainerRow>
              <IconContainer>
                <img src={qr_code} alt="qr code" />
              </IconContainer>
              <IconText>{t('Agrisure® Duracade® 5222A')}</IconText>
            </ProductContainerRow>
          )}
          {(applied_area || quantity) && (
            <ProductContainerRow>
              <IconContainer>
                <img src={field_area} alt="icon" />
              </IconContainer>
              <IconText>
                {applied_area?.toFixed(2) && `${applied_area.toFixed(2)} ${t('ha')}`}
                {quantity?.toFixed(2) && ` • ${quantity.toFixed(2)} ${t('Bags')}`}
              </IconText>
            </ProductContainerRow>
          )}
        </ProductFieldDetails>
      </ProductContainer>
    </ProductCards>
  );
};

export default RecommendationProductsCard;
